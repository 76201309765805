const style = {
  margin: 0,
  padding: 0,
  minHeight: '100vh',
};

const mainMenuContainerStyle = {
  width: '60px',
};

const secondaryMenuContainerStyle = {
  width: '200px',
};

const Workspace = ({ mainMenu, secondaryMenu, children }) => (
  <div className='workspace' style={style}>
    <div className='uk-grid uk-grid-collapse'>
      {mainMenu && (
        <div className='uk-width-auto' style={mainMenuContainerStyle}>
          {mainMenu}
        </div>
      )}
      {secondaryMenu && (
        <div className='uk-width-auto' style={secondaryMenuContainerStyle}>
          {secondaryMenu}
        </div>
      )}
      <div className='uk-width-expand'>{children}</div>
    </div>
  </div>
);

export default Workspace;
