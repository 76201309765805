import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { EditMembershipCodesForm } from 'ui/components';
import { useParams } from 'react-router-dom';
import { showMembershipCode, updateMembershipCode } from 'store/membershipCodes/membershipCodesSlice.ts';

export const EditMembershipCodesPage = () => {
  const dispatch = useDispatch();
  const { membershipCode } = useSelector((state) => state.membershipCodes);

  const { id } = useParams();
  useEffect(() => {
    dispatch(showMembershipCode(id));
  }, [dispatch, id]);

  return (
    <Page title='Modifica Membership Code' className='edit-custom-banner'>
      <Header title='Modifica Membership Code' />
      <WorkspaceContent>
        <EditMembershipCodesForm
          onUpdate={(id, data) => dispatch(updateMembershipCode({ id, data }))}
          membershipCode={membershipCode}
        />
      </WorkspaceContent>
    </Page>
  );
};

export default EditMembershipCodesPage;
