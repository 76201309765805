import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,

  users: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.SHOW_USERS_FETCH_LOADER]: reducerActions.showLoader,
  [actionTypes.HIDE_USERS_FETCH_LOADER]: reducerActions.hideLoader,
  [actionTypes.USERS_FETCHED]: reducerActions.usersFetched,
  [actionTypes.USERS_FETCH_FAILED]: reducerActions.usersFetchFailed,

  [actionTypes.SHOW_SINGLE_USER_LOADER]: reducerActions.showUserLoader,
  [actionTypes.HIDE_SINGLE_USER_LOADER]: reducerActions.hideUserLoader,

  [actionTypes.HIDDEN_RESULTS_SET]: reducerActions.hiddenResultsSet,
  [actionTypes.HIDDEN_RESULTS_SET_FAILED]:
    reducerActions.hiddenResultsSetFailed,

  [actionTypes.PREMIUM_USER_SET]: reducerActions.premiumUserSet,
  [actionTypes.PREMIUM_USER_SET_FAILED]:
    reducerActions.premiumUserSetFailed,

  [actionTypes.BANNED]: reducerActions.banned,
  [actionTypes.BAN_FAILED]: reducerActions.banFailed,

  [actionTypes.UNBANNED]: reducerActions.unbanned,
  [actionTypes.UNBAN_FAILED]: reducerActions.unbanFailed,

  [actionTypes.PREMIUM_EXPIRATION_DATE_SET]: reducerActions.premiumExpirationDateSet,
  [actionTypes.PREMIUM_EXPIRATION_DATE_SET_FAILED]: reducerActions.premiumExpirationDateSetFailed,
};

export default reducer(mapper, initialState);
