import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,

  offerCategories: null,
};

const mapper = {
  [actionTypes.SHOW_OFFER_CATEGORIES_LOADER]: reducerActions.showLoader,
  [actionTypes.HIDE_OFFER_CATEGORIES_LOADER]: reducerActions.hideLoader,
  [actionTypes.OFFER_CATEGORIES_FETCHED]: reducerActions.offerCategoriesFetched,
  [actionTypes.OFFER_CATEGORIES_FETCH_FAILED]:
    reducerActions.offerCategoriesFetchFailed,
};

export default reducer(mapper, initialState);
