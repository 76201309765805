import { combineReducers } from 'redux';
import { authenticationReducer } from 'store/authentication';
import { clubManagersReducer } from 'store/clubManagers';
import { offerCategoriesReducer } from 'store/offerCategories';
import { matchesReducer } from 'store/matches';
import { usersReducer } from 'store/users';
import { updateTasksReducer } from 'store/updateTasks';
import { customBannersReducer } from 'store/customBanners';
import { adsenseReducer } from 'store/adsense';
import { bannersReducer } from 'store/banners';
import { reportsReducer } from 'store/reports';
import { cardinalityReducer } from 'store/cardinality';
import { playersRequestsReducer } from 'store/playersRequests';
import { membershipCodesSlice } from './membershipCodes/membershipCodesSlice.ts';

export default () => combineReducers({
  authentication: authenticationReducer,
  clubManagers: clubManagersReducer,
  offerCategories: offerCategoriesReducer,
  matches: matchesReducer,
  users: usersReducer,
  update_tasks: updateTasksReducer,
  customBanners: customBannersReducer,
  membershipCodes: membershipCodesSlice.reducer,
  adsense: adsenseReducer,
  banners: bannersReducer,
  reports: reportsReducer,
  cardinality: cardinalityReducer,
  playersRequests: playersRequestsReducer,
});
