import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Loader,
  Header,
  WorkspaceContent,
  Button,
  Table,
  Tr,
  Th,
  Td,
  Icon,
} from 'lib';
import { Page } from 'ui/elements';
import { UsersMenu } from 'ui/navigation';

import { fetchClubManagers, approve, reject } from 'store/clubManagers/actions';

class ClubManagersPage extends PureComponent {
  componentDidMount() {
    this.props.fetch();
  }

  renderError() {
    return <p>errore</p>;
  }

  render() {
    const { loading, error, clubManagers } = this.props;

    let content = <Loader />;
    if (!loading) {
      if (error) {
        content = this.renderError();
      } else if (clubManagers) {
        content = (
          <Table
            className='club-managers-list'
            head={
              <Tr>
                <Th className='uk-table-shrink'>ID</Th>
                <Th className='uk-table-expand uk-text-left'>Utente</Th>
                <Th className='uk-table-expand uk-text-left'>Circolo</Th>
                <Th className='uk-width-1-4'>Stato</Th>
              </Tr>
            }
            rows={clubManagers}
            rowRenderer={(clubManager) => (
              <Tr key={`clubManager-${clubManager.id}`}>
                <Td className='uk-table-shrink uk-text-center'>
                  {clubManager.id}
                </Td>
                <Td>
                  <strong>
                    {clubManager.first_name} {clubManager.last_name}
                  </strong>
                  <br />
                  <Icon name='envelope-o' className='fa-fw' />{' '}
                  {clubManager.email || '-'}
                  <br />
                  <Icon name='phone' className='fa-fw' />{' '}
                  {clubManager.phone || '-'}
                </Td>
                <Td>
                  <strong>{clubManager.club.name}</strong>
                  <br />
                  <Icon name='phone' className='fa-fw' />{' '}
                  {clubManager.club.phone || '-'}
                  <br />
                  <Icon name='map-marker' className='fa-fw' />{' '}
                  {clubManager.club.legal_address || '-'}
                </Td>
                <Td>
                  {!clubManager.status && (
                    <div className='uk-alert uk-alert-warning uk-text-center'>
                      In attesa di verifica.
                    </div>
                  )}
                  {clubManager.notes && (
                    <p className='uk-text-center'>{clubManager.notes}</p>
                  )}
                  <div className='uk-grid uk-grid-small uk-child-width-1-2'>
                    <div className='uk-text-right'>
                      <Button
                        disabled={clubManager.status === 'approved'}
                        onClick={() => {
                          const notes = prompt('Motivazioni');
                          if (notes && notes !== '')
                            this.props.approve(clubManager.id, notes);
                        }}
                      >
                        <Icon name='check' className='fa-fw' />
                      </Button>
                    </div>
                    <div>
                      <Button
                        disabled={clubManager.status === 'rejected'}
                        onClick={() => {
                          const notes = prompt('Motivazioni');
                          if (notes && notes !== '')
                            this.props.reject(clubManager.id, notes);
                        }}
                      >
                        <Icon name='times' className='fa-fw' />
                      </Button>
                    </div>
                  </div>
                </Td>
              </Tr>
            )}
          />
        );
      }
    }

    return (
      <Page
        title='Gestori di circoli'
        className='club-managers'
        secondaryMenu={<UsersMenu />}
      >
        <Header title='Gestori di circoli' />

        <WorkspaceContent>{content}</WorkspaceContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.clubManagers });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchClubManagers(page)),
  approve: (clubManagerProfileId, notes) => dispatch(approve(clubManagerProfileId, notes)),
  reject: (clubManagerProfileId, notes) => dispatch(reject(clubManagerProfileId, notes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubManagersPage);
