import { Button } from 'lib';
import { useEffect, useState } from 'react';

export const SolveReportForm = (props) => {
  const [admin_notes, setAdminNotes] = useState('');

  const isValid = () => admin_notes !== '';

  useEffect(() => {
    if (props.admin_notes) {
      setAdminNotes(props.admin_notes);
    }
  }, [props.admin_notes]);

  const save = (event) => {
    event.preventDefault();
    props.onUpdate(props.id, admin_notes);
  };

  return (
    <form>
      <p>
        <strong>Note:</strong>
        <input
          type='text'
          value={admin_notes}
          onChange={(event) => setAdminNotes(event.target.value)
          }
        />
      </p>
      <Button disabled={!isValid()} onClick={save}>
        Risolvi
      </Button>
    </form>
  );

};
