import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,
  banner: null,
  banners: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.CUSTOM_BANNER_CREATED]: reducerActions.bannerCreated,
  [actionTypes.CUSTOM_BANNERS_FETCHED]: reducerActions.bannersFetched,
  [actionTypes.CUSTOM_BANNER_DELETED]: reducerActions.bannerDeleted,
  [actionTypes.CUSTOM_BANNER_FETCHED]: reducerActions.bannerFetched,
  [actionTypes.CUSTOM_BANNERS_ALL_FETCHED]: reducerActions.allBannersFetched,
};

export default reducer(mapper, initialState);
