import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { EditMembershipCodesForm, MembershipCodes, PaginationBar } from 'ui/components';
import { fetchMembershipCodes, createMembershipCode, deleteMembershipCode } from 'store/membershipCodes/membershipCodesSlice.ts';

const MembershipCodesPage = () => {
  const dispatch = useDispatch();
  const { page, totalPages, membershipCodes } = useSelector((state) => state.membershipCodes);
  useEffect(() => {
    dispatch(fetchMembershipCodes(page ?? 1));
  }, [page, dispatch]);

  return (
    <Page title='Codici Membership' className='custom-banners'>
      <Header title='Gestione Codici Membership' />
      <WorkspaceContent>
        <EditMembershipCodesForm onCreate={(data) => dispatch(createMembershipCode(data))} />
        <MembershipCodes membershipCodes={membershipCodes} deletes={(id) => dispatch(deleteMembershipCode(id))} />
        <PaginationBar
          currentPage={page}
          totalPages={totalPages}
          onPreviousPageClick={() => dispatch(fetchMembershipCodes(page - 1))}
          onNextPageClick={() => dispatch(fetchMembershipCodes(page + 1))}
          onPageClick={(page) => dispatch(fetchMembershipCodes(page))}
        />
      </WorkspaceContent>
    </Page>
  );

};


export default MembershipCodesPage;
