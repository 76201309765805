import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { CustomBannerForm, CustomBanners, PaginationBar } from 'ui/components';
import { fetchBanners, deleteBanner } from 'store/customBanners/actions';

class CustomBannersPage extends PureComponent {
  componentDidMount() {
    const { fetch, page } = this.props;
    fetch(page);
  }

  render() {
    const { page, totalPages, fetch } = this.props;
    return (
      <Page title='Custom Banner' className='custom-banners'>
        <Header title='Gestione Custom Banner' />
        <WorkspaceContent>
          <CustomBannerForm />
          <CustomBanners {...this.props} />
          <PaginationBar
            currentPage={page}
            totalPages={totalPages}
            onPreviousPageClick={() => fetch(page - 1)}
            onNextPageClick={() => fetch(page + 1)}
            onPageClick={(page) => fetch(page)}
          />
        </WorkspaceContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.customBanners });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchBanners(page)),
  deleteBanner: (id) => dispatch(deleteBanner(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomBannersPage);
