import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,
  playersRequests: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.PLAYERS_REQUESTS_FETCHED]: reducerActions.playersRequestsFetched,
  [actionTypes.PLAYERS_REQUESTS_ERROR]: reducerActions.playersRequestsError,
};

export default reducer(mapper, initialState);
