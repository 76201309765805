import { PureComponent } from 'react';

import { Button } from 'lib';

class UploadDumpForm extends PureComponent {
  state = {
    s3filename: '',
  };

  render() {
    return (
      <form className='update-task-form'>
        <input
          type='text'
          onChange={(e) => this.setState({ s3filename: e.target.value })}
          className='uk-margin-small-bottom'
        />

        <div className='actions'>
          <Button
            className='uk-margin-right'
            disabled={this.state.s3filename.length === 0}
            onClick={() => {
              this.props.runRestoreDump(this.state.s3filename);
              this.setState({ s3filename: '' });
            }}
          >
            Carica
          </Button>
        </div>
      </form>
    );
  }
}

export default UploadDumpForm;
