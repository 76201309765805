import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { RankingForm } from 'ui/components';
import { fetchNewCardinality } from 'store/cardinality/actions';

class RankingsPage extends PureComponent {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { year, cardinality } = this.props;
    return (
      <Page title='Cardinalità' className='cardinality'>
        <Header title='Nuova cardinalità' />
        <WorkspaceContent>
          <RankingForm year={year} cardinality={cardinality} />
        </WorkspaceContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.cardinality });
const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchNewCardinality()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RankingsPage);
