import * as theme from '../../theme';

const style = {
  fontFamily: theme.font.family,
  fontSize: theme.sizes.font.md,
  fontWeight: theme.font.weights.medium,
  textTransform: 'uppercase',
  color: theme.colors.black,
};

const Label = ({ children, otherProps }) => (
  <label {...otherProps} style={style}>
    {children}
  </label>
);

export default Label;
