import { isBannerManager } from 'helpers';
import { Button, Table, Td, Th, Tr } from 'lib';
import { Link } from 'react-router-dom';

const getBannerUrl = (banner) => banner.type === 'ADSENSE'
  ? `/adsense/${banner.bannerable_id}/modifica`
  : `/custom-banner/${banner.bannerable_id}/modifica`;

const restricted = isBannerManager();

export const Banners = ({ banners, deleteBanner }) => (
  <Table
    className='update-tasks-list uk-margin-top'
    head={
      <Tr>
        <Th className='uk-table-shrink'>ID</Th>
        <Th className='uk-table-shrink'>Slot</Th>
        <Th className='uk-table-shrink'>Banner</Th>
        <Th className='uk-table-shrink'>Azioni</Th>
      </Tr>
    }
    rows={banners}
    rowRenderer={(banner) => (
      <Tr key={`update-task-${banner.id}`}>
        <Td className='uk-table-shrink'>{banner.id}</Td>
        <Td>{banner.slot}</Td>
        <Td>
          {banner.bannerable_id ? <a href={getBannerUrl(banner)}>{banner.type}</a> : <p>{banner.type}</p>}
        </Td>
        <Td>
          <Link to={`/gestione-banner/${banner.id}/modifica`}>Modifica</Link>
          {!restricted && <Button
            onClick={() => {
              if (
                window.confirm('Sei sicuro di voler eliminare questo banner?')
              )
                deleteBanner(banner.id);
            }}
          >
            Elimina
          </Button>}
        </Td>
      </Tr>
    )}
  />
);
