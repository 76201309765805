import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { UploadDumpForm } from 'ui/components';
import { runRestoreDump } from 'store/updateTasks/actions';

class DatabasePage extends PureComponent {
  render() {
    return (
      <Page title='Database' className='database'>
        <Header title='Restore dump' />
        <WorkspaceContent>
          <UploadDumpForm runRestoreDump={this.props.runRestoreDump} />
        </WorkspaceContent>
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  runRestoreDump: (file) => dispatch(runRestoreDump(file)),
});

export default connect(null, mapDispatchToProps)(DatabasePage);
