import API from 'api';
import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

export const save = (data, afterSaveCallback) => (dispatch) => {
  API.banners.create(
    { positioned_banner: data },
    (response) => {
      UIkit.notification('Banner inserito con successo', {
        status: 'success',
      });
      dispatch(bannerCreated({ ...response.positioned_banner }));
      afterSaveCallback();
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    }
  );
};

export const fetchBanners = (page) => (dispatch) => {
  API.banners.fetch(
    page,
    (response) => {
      dispatch(bannersFetched(response));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    }
  );
};

export const deleteBanner = (id) => (dispatch) => {
  API.banners.deleteBanner(
    id,
    (response) => {
      UIkit.notification('Banner eliminato con successo', {
        status: 'success',
      });
      dispatch(bannersDeleted(response.positioned_banner));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    }
  );
};

export const fetchBanner = (id) => (dispatch) => {
  API.banners.show(
    id,
    (response) => {
      dispatch(bannerFetched(response));
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    }
  );
};

export const updateBanner = (id, data) => (dispatch) => {
  API.banners.update(
    id,
    data,
    () => {
      UIkit.notification('Banner aggiornato con successo', {
        status: 'success',
      });
      window.location.href = '/gestione-banner';
    },
    (error) => {
      dispatch(bannerActionFailed(error));
    }
  );
};

const bannersFetched = (response) => ({
  type: actionTypes.BANNERS_FETCHED,
  payload: response,
});

const bannerCreated = (banner) => ({
  type: actionTypes.BANNER_CREATED,
  payload: banner,
});

const bannersDeleted = (banner) => ({
  type: actionTypes.BANNER_DELETED,
  payload: banner,
});

const bannerFetched = (banner) => ({
  type: actionTypes.BANNER_FETCHED,
  payload: banner,
});

const bannerActionFailed = (error) => ({
  type: actionTypes.BANNER_ACTION_FAILED,
  payload: error,
});
