import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Page } from 'ui/elements';
import {
  Loader,
  Header,
  WorkspaceContent,
  Table,
  Tr,
  Th,
  Td,
  Button,
} from 'lib';
import {
  fetchUpdateTasks,
  saveUpdateTask,
  cancelCacheTask,
  restartServersTask,
  runTask,
  setTaskPending,
} from 'store/updateTasks/actions';
import { UploadTaskForm } from 'ui/components';

const UpdateTasksPage = (props) => {
  const [avoidSendMail, setAvoidSendMail] = useState(false);
  useEffect(() => {
    props.fetch();
    //eslint-disable-next-line
  }, []);


  const renderError = () => <p>errore</p>;

  const { loading, error, update_tasks } = props;

  let content = <Loader />;
  if (!loading) {
    if (error) {
      content = renderError();
    } else if (update_tasks) {
      content = (
        <Table
          className='update-tasks-list uk-margin-top'
          head={
            <Tr>
              <Th className='uk-table-shrink'>ID</Th>
              <Th className='uk-table-expand'>
                Nome file/Nome aggiornamento
              </Th>
              <Th className='uk-table-shrink'>Stato</Th>
              <Th className='uk-table-expand'>Log</Th>
              <Th className='uk-table-shrink'>Data upload</Th>
              <Th className='uk-table-shrink'>Ultimo aggiornamento</Th>
              <Th className='uk-table-shrink'>Azioni</Th>
            </Tr>
          }
          rows={update_tasks}
          rowRenderer={(update_task) => (
            <Tr key={`update-task-${update_task.id}`}>
              <Td className='uk-table-shrink'>{update_task.id}</Td>
              <Td>
                {update_task.name ? update_task.name : update_task.file}
              </Td>
              <Td>{update_task.status}</Td>
              <Td
                dangerouslySetInnerHTML={{
                  __html: (update_task.log || '').replace(/\n/g, '<br>'),
                }}
              />
              <Td>
                {moment
                  .unix(update_task.upload_date)
                  .format('DD/MM/YYYY HH:mm')}
              </Td>
              <Td>
                {moment
                  .unix(update_task.last_modification_date)
                  .format('DD/MM/YYYY HH:mm')}
              </Td>
              <Td>
                {update_task.status === 'pending' ? (
                  <>
                    <input
                      type='checkbox'
                      checked={avoidSendMail}
                      onChange={() => setAvoidSendMail(!avoidSendMail)}
                    />
                    <Button
                      onClick={() => {
                        if (
                          window.confirm(
                            'Sei sicuro di voler eseguire questo aggiornamento?'
                          )
                        )
                          props.runTask(update_task.id, avoidSendMail);
                      }}
                    >
                      Esegui
                    </Button>
                  </>
                ) : <Button kind='secondary'
                  onClick={() => {
                    if (
                      window.confirm(
                        'Sei sicuro di voler riattivare questo aggiornamento?'
                      )
                    )
                      props.setTaskPending(update_task.id);
                  }}
                >
                  Riattiva
                </Button>}
              </Td>
            </Tr>
          )}
        />
      );
    }
  }

  return (
    <Page title='Aggiornamento dati' className='update-tasks'>
      <Header title='Aggiornamento dati' />
      <WorkspaceContent>
        <UploadTaskForm
          saveUpdateTask={props.saveUpdateTask}
          cancelCacheTask={props.cancelCacheTask}
          restartServersTask={props.restartServersTask}
        />
        {content}
      </WorkspaceContent>
    </Page>
  );

};

const mapStateToProps = (state) => ({ ...state.update_tasks });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchUpdateTasks(page)),
  saveUpdateTask: (file) => dispatch(saveUpdateTask(file)),
  cancelCacheTask: () => dispatch(cancelCacheTask()),
  restartServersTask: () => dispatch(restartServersTask()),
  runTask: (id, avoidSendMail) => dispatch(runTask(id, avoidSendMail)),
  setTaskPending: (id) => dispatch(setTaskPending(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTasksPage);
