import { Button } from 'lib';
import { useEffect, useState } from 'react';

export const EditCustomBannerForm = (props) => {
  const [id, setId] = useState('');
  const [destination, setDestination] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (props.banner) {
      setId(props.banner.id);
      setDestination(props.banner.destination);
      setImage(props.banner.image);
    }
  }, [props.banner]);

  const isValid = () => (image == null || image !== '') && destination !== '';

  const handleSave = (event) => {
    event.preventDefault();
    if (image) props.onUpdate(id, {
      id,
      destination,
      image,
    });
    else
      props.onUpdate(id, {
        destination: destination,
      });
  };

  const handleFile = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => setImage(reader.result);
    reader.readAsDataURL(file);
  };


  return (
    <form>
      <p>
        <strong>Immagine:</strong>{' '}
        {image && (
          <img src={image} alt='Custom Banner' />
        )}
        <input type='file' onChange={handleFile} />
      </p>
      <p>
        <strong>Destinazione:</strong>
        <input
          type='text'
          value={destination}
          onChange={(event) => setDestination(event.target.value)
          }
        />
      </p>
      <Button onClick={handleSave} disabled={!isValid()}>
        Salva
      </Button>
    </form>
  );

};
