import { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Page } from 'ui/elements';
import { PaginationBar } from 'ui/components';
import { Loader, Header, WorkspaceContent, Table, Tr, Th, Td } from 'lib';
import { fetchPlayersRequests } from 'store/playersRequests/actions';

class PlayersRequestsPage extends PureComponent {
  componentDidMount() {
    this.props.fetch();
  }

  renderError() {
    return <p>errore</p>;
  }

  render() {
    const {
      loading,
      error,
      playersRequests,
      page,
      totalPages,
      fetch,
    } = this.props;

    let content = <Loader />;
    if (!loading) {
      if (error) {
        content = this.renderError();
      } else if (playersRequests) {
        content = (
          <Table
            className='update-tasks-list uk-margin-top'
            head={
              <Tr>
                <Th className='uk-table-shrink'>ID</Th>
                <Th className='uk-table-expand'>Utente</Th>
                <Th className='uk-table-expand'>Giocatore</Th>
                <Th className='uk-table-shrink'>Tessera</Th>
                <Th className='uk-table-shrink'>Data richiesta</Th>
              </Tr>
            }
            rows={playersRequests}
            rowRenderer={(player_request) => (
              <Tr key={`update-task-${player_request.id}`}>
                <Td className='uk-table-shrink'>{player_request.id}</Td>
                <Td>{player_request.user.name}</Td>
                <Td>{player_request.fit_player_profile.name}</Td>
                <Td>{player_request.fit_player_id}</Td>
                <Td>
                  {moment
                    .unix(player_request.created_at)
                    .format('DD/MM/YYYY HH:mm')}
                </Td>
              </Tr>
            )}
          />
        );
      }
    }

    return (
      <Page title='Richieste giocatori' className='players-requests'>
        <Header title='Richieste giocatori' />
        <WorkspaceContent>{content}</WorkspaceContent>
        <PaginationBar
          currentPage={page}
          totalPages={totalPages}
          onPreviousPageClick={() => fetch(page - 1)}
          onNextPageClick={() => fetch(page + 1)}
          onPageClick={(page) => fetch(page)}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.playersRequests });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchPlayersRequests(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayersRequestsPage);
