import { PureComponent } from 'react';
import { call } from 'api';
import { Button } from 'lib';

class SearchablePlayer extends PureComponent {
  state = {
    input: '',
    playerResults: null,
  };

  renderPlayersResults() {
    const { playerResults, input } = this.state;
    if (input.length === 0) return null;
    return (
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Classifica</th>
            <th>Tessera</th>
            <th>Codice Anagrafica</th>
          </tr>
        </thead>
        <tbody>
          {playerResults.map((playerResult) => (
            <tr
              key={playerResult.id}
              onClick={() => {
                this.setState({ input: '', playerResults: null }, () => this.props.onChangePlayerHandler(playerResult)
                );
              }}
            >
              <td>{playerResult.id}</td>
              <td>{playerResult.name}</td>
              <td>{playerResult.rank}</td>
              <td>{playerResult.card_number}</td>
              <td>{playerResult.anagrafica_code}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  setResults() {
    const { input } = this.state;
    call(
      `fit-player-profiles/search-for-merge/${input}`,
      { method: 'GET' },
      (data) => {
        this.setState({
          playerResults: data,
        });
      },
      (error) => {
        alert(error);
      }
    );
  }

  onChangeHandler = (e) => {
    this.setState({ input: e.target.value }, this.setResults);
  };

  renderNoResults() {
    return <p>Nessun giocatore corrispondente al criterio di ricerca</p>;
  }

  renderResults() {
    const { playerResults, input } = this.state;
    return (
      <div className='players-results'>
        {playerResults.length === 0 && input.length !== 0
          ? this.renderNoResults()
          : this.renderPlayersResults()}
      </div>
    );
  }

  renderPlayer() {
    const { player } = this.props;
    return (
      <div className='selected-player'>
        <p>
          <strong style={{ marginRight: '5px' }}>Giocatore selezionato</strong>
          <Button onClick={() => this.props.onChangePlayerHandler(null)}>
            Togli
          </Button>
        </p>
        <p>
          <strong>ID:</strong> {player.id}
        </p>
        <p>
          <strong>Nome:</strong> {player.name}
        </p>
        <p>
          <strong>Classifica:</strong> {player.rank}
        </p>
        <p>
          <strong>Tessera:</strong> {player.card_number}
        </p>
        <p>
          <strong>Codice Angrafica:</strong> {player.anagrafica_code}
        </p>
      </div>
    );
  }

  renderInput() {
    const { input, playerResults } = this.state;
    return (
      <div>
        <input type='text' value={input} onChange={this.onChangeHandler} />
        {playerResults ? this.renderResults() : null}
      </div>
    );
  }

  render() {
    const { label, player } = this.props;
    return (
      <div className='searchable-player'>
        <label>{label}</label>
        {player ? this.renderPlayer() : this.renderInput()}
      </div>
    );
  }
}

export default SearchablePlayer;
