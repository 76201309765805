import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,

  matches: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.SHOW_MATCHES_LOADER]: reducerActions.showLoader,
  [actionTypes.HIDE_MATCHES_LOADER]: reducerActions.hideLoader,
  [actionTypes.MATCHES_FETCHED]: reducerActions.matchesFetched,
  [actionTypes.MATCHES_FETCH_FAILED]: reducerActions.matchesFetchFailed,
  [actionTypes.SET_MATCH_RESULT]: reducerActions.setMatchResultReducer,
};

export default reducer(mapper, initialState);
