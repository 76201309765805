import { call } from 'api';

export const create = (data, successCallback, errorCallback) => call(
  'positioned-banners',
  { method: 'POST', body: JSON.stringify(data) },
  successCallback,
  errorCallback
);

export const fetch = (page, successCallback, errorCallback) => call(
  `positioned-banners?page=${page}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const deleteBanner = (id, successCallback, errorCallback) => call(
  `positioned-banners/${id}`,
  { method: 'DELETE' },
  successCallback,
  errorCallback
);

export const show = (id, successCallback, errorCallback) => call(
  `positioned-banners/by-id/${id}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const update = (id, data, successCallback, errorCallback) => call(
  `positioned-banners/${id}`,
  { method: 'PUT', body: JSON.stringify(data) },
  successCallback,
  errorCallback
);
