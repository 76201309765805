import { useEffect } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { EditAdsenseForm } from 'ui/components';
import { fetchAdsense, updateAdsense } from 'store/adsense/actions';
import { useParams } from 'react-router-dom';

export const EditAdsensePage = (props) => {
  const { id } = useParams();
  useEffect(() => {
    props.fetch(id);
    //eslint-disable-next-line
  }, []);

  return (
    <Page title='Modifica Adsense' className='edit-adsense'>
      <Header title='Modifica Adsense' />
      <WorkspaceContent>
        <EditAdsenseForm
          {...props.adsense}
          onUpdate={props.update}
        />
      </WorkspaceContent>
    </Page>
  );
};

const mapStateToProps = (state) => ({ ...state.adsense });
const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(fetchAdsense(id)),
  update: (id, data) => dispatch(updateAdsense(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdsensePage);
