import Radium from 'radium';

import * as theme from '../../theme';

const SECONDARY = 'secondary';
const OUTLINED = 'outlined';

const baseStyle = {
  backgroundColor: theme.colors.primaryColor,
  color: theme.colors.white,
  fontFamily: theme.font.family,
  textTransform: 'uppercase',
  fontSize: theme.sizes.font.md,
  padding: `${theme.sizes.gutter.xs} ${theme.sizes.gutter.sm}`,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.colors.primaryColor,
  borderRadius: theme.sizes.borderRadius,
  cursor: 'pointer',
  outline: 'none',
  ':hover': {
    backgroundColor: theme.colors.secondaryColor,
    borderColor: theme.colors.secondaryColor,
  },
  ':active': {
    backgroundColor: theme.colors.primaryColor,
    borderColor: theme.colors.primaryColor,
  },
};

const secondaryStyle = {
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.white,
  color: theme.colors.primaryColor,
  ':hover': {
    backgroundColor: theme.colors.secondaryColor,
    borderColor: theme.colors.secondaryColor,
    color: theme.colors.white,
  },
  ':active': {
    backgroundColor: theme.colors.primaryColor,
    borderColor: theme.colors.primaryColor,
    color: theme.colors.white,
  },
};

const outlinedStyle = {
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.secondaryColor,
  color: theme.colors.primaryColor,
  ':hover': {
    backgroundColor: theme.colors.secondaryColor,
    borderColor: theme.colors.secondaryColor,
    color: theme.colors.white,
  },
  ':active': {
    backgroundColor: theme.colors.primaryColor,
    borderColor: theme.colors.primaryColor,
    color: theme.colors.white,
  },
};

const disabledStyle = {
  cursor: 'not-allowed',
  backgroundColor: theme.colors.lightGrey,
  borderColor: theme.colors.lightGrey,
  color: theme.colors.white,
};

const Button = ({ kind, disabled, children, ...otherProps }) => {
  let style = baseStyle;
  switch (kind) {
    case SECONDARY: {
      style = { ...style, ...secondaryStyle };
      break;
    }
    case OUTLINED: {
      style = { ...style, ...outlinedStyle };
      break;
    }
    default:
      break;
  }
  if (disabled) style = { ...style, ...disabledStyle };

  return (
    <button {...otherProps} style={style} disabled={!!disabled}>
      {children}
    </button>
  );
};

export default Radium(Button);
