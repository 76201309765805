import update from 'immutability-helper';

export const reportsFetched = (state, action) => {
  const { total_pages, page, data } = action.payload;
  return update(state, {
    totalPages: { $set: total_pages },
    page: { $set: page },
    reports: {
      $set: data,
    },
  });
};

export const reportFetched = (state, action) => update(state, { report: { $set: action.payload } });
