import update from 'immutability-helper';

export const showLoader = (state) => update(state, { loading: { $set: true } });

export const hideLoader = (state) => update(state, { loading: { $set: false } });

export const matchesFetched = (state, action) => update(state, { matches: { $set: action.matches } });

export const matchesFetchFailed = (state) => update(state, { error: { $set: true } });

export const setMatchResultReducer = (state, action) => update(state, {
  matches: {
    $set: state.matches.map((match) => match.id === action.matchId
      ? { ...match, result: action.result }
      : match
    ),
  },
});
