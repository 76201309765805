import { Button } from 'lib';
import { Page } from 'ui/elements';

const DashboardPage = () => (
  <Page title='Bacheca' className='dashboard'>
    <h1>Benvenuto nella tua bacheca</h1>
    <Button onClick={() => {
      window.location.href = 'https://emish89:o2o91989@api.tennistalker.it/rails/performance/crashes';
    }}>
      API error 500
    </Button>

    <Button className='uk-margin-left' onClick={() => {
      window.location.href = 'https://analytics.google.com/analytics/web/#/p291660364/reports/intelligenthome';
    }}>
      Analytics
    </Button>

    <Button className='uk-margin-left' onClick={() => {
      window.location.href = 'https://tennistalker-sp.admin.rechargeapps.com/merchant/home';
    }}>
      Recharge
    </Button>

    <Button className='uk-margin-left' onClick={() => {
      window.location.href = 'https://admin.shopify.com/store/tennistalker';
    }}>
      Shopify
    </Button>

    <Button className='uk-margin-left' onClick={() => {
      window.location.href = 'https://fatturazione.evolutionadv.it/publisher/home';
    }}>
      Evolution ADV
    </Button>

  </Page>
);

export default DashboardPage;
