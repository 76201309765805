import classNames from 'classnames';

import * as theme from '../theme';

const baseStyle = {
  paddingTop: theme.sizes.gutter.sm,
  paddingRight: theme.sizes.gutter.sm,
  paddingBottom: theme.sizes.gutter.sm,
  paddingLeft: theme.sizes.gutter.sm,
  backgroundColor: theme.colors.lightGrey,
  textTransform: 'uppercase',
  color: theme.colors.black,
  fontSize: theme.sizes.font.md,
  fontWeight: theme.font.weights.normal,
};

export const Th = ({ className, style, children, ...otherProps }) => (
  <th
    className={classNames('th', className)}
    style={{ ...baseStyle, ...style }}
    {...otherProps}
  >
    {children}
  </th>
);
