import { Button, Table, Td, Th, Tr } from 'lib';
import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { deleteTarget } from 'store/reports/actions';

class Reports extends PureComponent {
  reportableType = (type) => {
    switch (type) {
      case 'post':
        return 'post';
      case 'comment':
        return 'commento';
      case 'user':
        return 'user';
      default:
        return '';
    }
  };

  reportableContent = (reportable) => {
    switch (reportable.type) {
      case 'post':
        return <a href={reportable.url}>{reportable.url}</a>;
      case 'comment':
        return reportable.content;
      case 'user':
        return <a href={reportable.url}>{reportable.url}</a>;
      default:
        return '';
    }
  };

  target = (reportable) => (
    <Fragment>
      {this.reportableType(reportable.type)} -{' '}
      {this.reportableContent(reportable)}
    </Fragment>
  );

  deleteButton = (report) => (
    <Button
      onClick={() => {
        if (window.confirm('Sicuro di eliminare l\'elemento segnalato?')) {
          this.props.deleteTarget(report.id);
        }
      }}
    >
      Elimina elemento segnalato
    </Button>
  );

  solveButton = (report) => (
    <Link to={`/segnalazioni/${report.id}/risolvi`}>Risolvi</Link>
  );

  editButton = (report) => (
    <Link to={`/segnalazioni/${report.id}/risolvi`}>Modifica</Link>
  );

  render() {
    return (
      <Table
        className='report-list uk-margin-top'
        head={
          <Tr>
            <Th className='uk-table-shrink'>ID</Th>
            <Th className='uk-table-shrink'>Autore</Th>
            <Th className='uk-table-shrink'>Messaggio</Th>
            <Th className='uk-table-shrink'>Target</Th>
            <Th className='uk-table-shrink'>Note Admin</Th>
            <Th className='uk-table-shrink'>Azioni</Th>
          </Tr>
        }
        rows={this.props.reports}
        rowRenderer={(report) => (
          <Tr key={`update-task-${report.id}`}>
            <Td className='uk-table-shrink'>{report.id}</Td>
            <Td className='uk-table-shrink'>
              {report.author.id} - {report.author.name}
            </Td>
            <Td className='uk-table-shrink'>{report.notes}</Td>
            <Td className='uk-table-shrink'>
              {report.reportable && this.target(report.reportable)}
            </Td>
            <Td className='uk-table-shrink'>{report.admin_notes}</Td>
            <Td>
              {report.status === 'pending'
                ? this.solveButton(report)
                : this.editButton(report)}
              {report.reportable && this.deleteButton(report)}
            </Td>
          </Tr>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteTarget: (id) => dispatch(deleteTarget(id)),
});

export default connect(null, mapDispatchToProps)(Reports);
