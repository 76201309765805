import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { AdsenseForm, Adsenses, PaginationBar } from 'ui/components';
import { fetchAdsenses, deleteAdsense } from 'store/adsense/actions';

class AdsensePage extends PureComponent {
  componentDidMount() {
    const { fetch, page } = this.props;
    fetch(page);
  }

  render() {
    const { page, totalPages, fetch } = this.props;
    return (
      <Page title='Adsense' className='adsenses'>
        <Header title='Gestione Adsense' />
        <WorkspaceContent>
          <AdsenseForm />
          <Adsenses {...this.props} />
          <PaginationBar
            currentPage={page}
            totalPages={totalPages}
            onPreviousPageClick={() => fetch(page - 1)}
            onNextPageClick={() => fetch(page + 1)}
            onPageClick={(page) => fetch(page)}
          />
        </WorkspaceContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.adsense });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchAdsenses(page)),
  deleteAdsense: (id) => dispatch(deleteAdsense(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdsensePage);
