import API from 'api';
import * as actionTypes from './actionTypes';

const showLoader = () => ({ type: actionTypes.SHOW_CLUB_MANAGERS_FETCH_LOADER });
const hideLoader = () => ({ type: actionTypes.HIDE_CLUB_MANAGERS_FETCH_LOADER });

export const fetchClubManagers = (page) => (dispatch) => {
  dispatch(showLoader());

  API.clubManagers.index(
    page,
    (response) => {
      dispatch(clubManagersFetched({ ...response }));
      dispatch(hideLoader());
    },
    (error) => {
      dispatch(clubManagersFetchFailed(error));
      dispatch(hideLoader());
    }
  );
};

const clubManagersFetched = ({ total_pages, club_manager_profiles }) => ({
  type: actionTypes.CLUB_MANAGERS_FETCHED,
  totalPages: total_pages,
  clubManagers: club_manager_profiles,
});
const clubManagersFetchFailed = (error) => ({
  type: actionTypes.CLUB_MANAGERS_FETCH_FAILED,
  error,
});

export const approve = (clubManagerProfileId, notes) => (dispatch) => {
  // dispatch(showUserLoader(userId, "isBanned"))

  API.clubManagers.approve(
    clubManagerProfileId,
    notes,
    () => {
      dispatch(clubManagerApproved(clubManagerProfileId, notes));
      // dispatch(hideUserLoader(userId, "isBanned"))
    },
    (error) => {
      dispatch(clubManagerApprovalFailed(error));
      // dispatch(hideUserLoader(userId, "isBanned"))
    }
  );
};

const clubManagerApproved = (clubManagerProfileId, notes) => ({
  type: actionTypes.CLUB_MANAGER_APPROVED,
  clubManagerProfileId,
  notes,
});
const clubManagerApprovalFailed = (error) => ({
  type: actionTypes.CLUB_MANAGER_APPROVAL_FAILED,
  error,
});

export const reject = (clubManagerProfileId, notes) => (dispatch) => {
  // dispatch(showUserLoader(userId, "isBanned"))

  API.clubManagers.reject(
    clubManagerProfileId,
    notes,
    () => {
      dispatch(clubManagerRejected(clubManagerProfileId, notes));
      // dispatch(hideUserLoader(userId, "isBanned"))
    },
    (error) => {
      dispatch(clubManagerRejectionFailed(error));
      // dispatch(hideUserLoader(userId, "isBanned"))
    }
  );
};

const clubManagerRejected = (clubManagerProfileId, notes) => ({
  type: actionTypes.CLUB_MANAGER_REJECTED,
  clubManagerProfileId,
  notes,
});
const clubManagerRejectionFailed = (error) => ({
  type: actionTypes.CLUB_MANAGER_REJECTION_FAILED,
  error,
});
