import DocumentMeta from 'react-document-meta';
import classNames from 'classnames';

import { PrimaryMenu } from 'ui/navigation';
import { Workspace } from 'lib';

const Page = ({ title, className, secondaryMenu, withoutMenus, children }) => (
  <DocumentMeta title={`${title} - Tennistalker Backoffice`}>
    <div className={classNames('page', className)}>
      <Workspace
        mainMenu={!withoutMenus && <PrimaryMenu />}
        secondaryMenu={secondaryMenu}
      >
        {children}
      </Workspace>
    </div>
  </DocumentMeta>
);

export default Page;
