import { call } from 'api';

export const all = (successCallback, errorCallback) => call('adsense_slots/all', { method: 'GET' }, successCallback, errorCallback);

export const create = (data, successCallback, errorCallback) => call(
  'adsense_slots',
  { method: 'POST', body: JSON.stringify(data) },
  successCallback,
  errorCallback
);

export const fetch = (page, successCallback, errorCallback) => call(
  `adsense_slots?page=${page}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const deleteAdsense = (id, successCallback, errorCallback) => call(
  `adsense_slots/${id}`,
  { method: 'DELETE' },
  successCallback,
  errorCallback
);

export const show = (id, successCallback, errorCallback) => call(`adsense_slots/${id}`, { method: 'GET' }, successCallback, errorCallback);

export const update = (id, data, successCallback, errorCallback) => call(
  `adsense_slots/${id}`,
  { method: 'PUT', body: JSON.stringify(data) },
  successCallback,
  errorCallback
);
