import { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import validator from 'validator';

import { login } from 'store/authentication/actions';
import { Button, Loader, TextInput } from 'lib';

class LoginForm extends PureComponent {
  state = {
    email: '',
    password: '',
  };

  isValid() {
    return [
      !validator.isEmpty(this.state.email) &&
      validator.isEmail(this.state.email),
      !validator.isEmpty(this.state.password),
    ].every((isValid) => isValid);
  }

  renderContent = () => (
    <div>
      {this.props.error && (
        <div className='uk-alert uk-alert-danger uk-margin-bottom'>
          {this.props.error}
        </div>
      )}
      <div className='uk-margin-small-bottom'>
        <TextInput
          type='email'
          placeholder='email'
          icon='envelope-o'
          iconSide='left'
          value={this.state.email}
          onChange={(event) => this.setState({ email: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.props.login(this.state);
            }
          }}
        />
        {this.state.email !== '' && !validator.isEmail(this.state.email) && (
          <p className='uk-text-danger uk-text-small uk-margin-small-top'>
            questo indirizzo e-mail non sembra valido...
          </p>
        )}
      </div>
      <div className='uk-margin-bottom'>
        <TextInput
          type='password'
          placeholder='password'
          icon='lock'
          iconSide='left'
          value={this.state.password}
          onChange={(event) => this.setState({ password: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.props.login(this.state);
            }
          }}
        />
      </div>
      <Button
        disabled={!this.isValid()}
        onClick={() => this.props.login(this.state)}
      >
        Accedi
      </Button>
    </div>
  );

  render() {
    const content = this.props.loading ? <Loader /> : this.renderContent();

    return (
      <div className={classNames('login-form', this.props.className)}>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.authentication });
const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(login(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
