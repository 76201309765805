import { PureComponent } from 'react';

import { Page } from 'ui/elements';
import { LoginForm } from 'ui/components';

class LoginPage extends PureComponent {
  render() {
    return (
      <Page title='Accedi' className='login' withoutMenus>
        <div className='uk-padding-large'>
          <h1>Tennistalker Backoffice</h1>
          <div className='uk-width-1-4 uk-background-default'>
            <LoginForm className='uk-margin-large-top' />
          </div>
        </div>
      </Page>
    );
  }
}

export default LoginPage;
