import { Provider } from 'react-redux';
import { RoutesObj } from 'Routes';
import store from './store/store';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <RoutesObj />
    </BrowserRouter>
  </Provider>
);
