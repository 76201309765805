import { Button, Icon, Table, Td, Th, Tr } from 'lib';
import { Link } from 'react-router-dom';
import UIkit from 'uikit';

export const MembershipCodes = ({ membershipCodes, deletes }) => (
  <Table
    className='update-tasks-list uk-margin-top'
    head={
      <Tr>
        <Th className='uk-table-shrink'>Data</Th>
        <Th className='uk-table-shrink'>ID</Th>
        <Th className='uk-table-shrink'>UserID</Th>
        <Th className='uk-table-shrink'>ShopifyID</Th>
        <Th className='uk-table-shrink'>Consumato</Th>
        <Th className='uk-table-shrink'>Email</Th>
        <Th className='uk-table-shrink'>Durata</Th>
        <Th className='uk-table-shrink'>Azioni</Th>
      </Tr>
    }
    rows={membershipCodes}
    rowRenderer={(membershipCode) => (
      <Tr key={`update-task-${membershipCode.id}`}>
        <Td className='uk-table-shrink'>
          {new Date(membershipCode.created_at).toLocaleString()}
        </Td>
        <Td className='uk-table-shrink'>
          <a
            href={`https://tennistalker.it/diventa-member/2989${membershipCode.id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {`2989${membershipCode.id}`}
          </a>
        </Td>
        <Td>
          <a
            href={`https://tennistalker.it/utente/${membershipCode.user_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {membershipCode.user_id}
          </a>
        </Td>
        <Td>
          <a
            href={`https://tennistalker.myshopify.com/admin/orders/${membershipCode.shopify_order_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {membershipCode.shopify_order_id}
          </a>
        </Td>
        <Td className='uk-table-shrink'>{membershipCode.consumed?.toString()}</Td>
        <Td className='uk-table-shrink'>{membershipCode.email}
          <Icon name='copy' className='uk-margin-small-left' onClick={() => {
            navigator.clipboard.writeText(membershipCode.email);
            UIkit.notification('Email copiata.', {
              status: 'success',
            });
          }} />
        </Td>
        <Td className='uk-table-shrink'>{membershipCode.duration}</Td>
        <Td>
          <Link to={`/codici-membership/${membershipCode.id}/modifica`}>Modifica</Link>
          <Button
            onClick={() => {
              if (
                window.confirm('Sei sicuro di voler eliminare questo banner?')
              )
                deletes(membershipCode.id);
            }}
          >
            Elimina
          </Button>
        </Td>
      </Tr>
    )}
  />
);
