import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,

  update_tasks: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.SHOW_UPDATE_TASKS_FETCH_LOADER]: reducerActions.showLoader,
  [actionTypes.HIDE_UPDATE_TASKS_FETCH_LOADER]: reducerActions.hideLoader,
  [actionTypes.UPDATE_TASKS_FETCHED]: reducerActions.updateTasksFetched,
  [actionTypes.UPDATE_TASKS_FETCH_FAILED]:
    reducerActions.updateTasksFetchFailed,
};

export default reducer(mapper, initialState);
