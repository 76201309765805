export default {
  family: 'Roboto',

  weights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
};
