import * as theme from '../theme';

const style = {
  paddingTop: theme.sizes.gutter.md,
  marginBottom: theme.sizes.gutter.rg,
  backgroundColor: theme.colors.white,
};

const iconWrapperStyle = {
  width: theme.sizes.gutter.xl,
  color: theme.colors.black,
  textAlign: 'center',
};

const pretitleStyle = {
  margin: 0,
  padding: 0,
  textTransform: 'uppercase',
  fontSize: theme.sizes.font.sm,
  fontFamily: theme.font.family,
  color: theme.colors.darkGrey,
};

const titleStyle = {
  margin: 0,
  padding: 0,
  textTransform: 'uppercase',
  fontSize: theme.sizes.font.xl,
  fontFamily: theme.font.family,
  color: theme.colors.black,
};

const mainContentWrapperStyle = {
  paddingBottom: theme.sizes.gutter.sm,
  borderBottom: `2px solid ${theme.colors.lightGrey}`,
};

const childrenWrapperStyle = {
  paddingRight: theme.sizes.gutter.rg,
};

const Header = ({ pretitle, title, icon, children }) => {
  const iconWrapper = (
    <div className='icon-wraper uk-width-auto' style={iconWrapperStyle}>
      {icon}
    </div>
  );

  const childrenWrapper = (
    <div className='children-wrapper uk-width-auto'>
      <div style={childrenWrapperStyle}>{children}</div>
    </div>
  );

  const mainContentWrapper = (
    <div className='main-content-wrapper uk-width-expand'>
      <div style={mainContentWrapperStyle}>
        <div className='uk-grid uk-flex-middle'>
          <div className='uk-width-expand'>
            <h4 style={pretitleStyle}>
              {pretitle}
              &nbsp;
            </h4>
            <h1 style={titleStyle}>{title}</h1>
          </div>
          {childrenWrapper}
        </div>
      </div>
    </div>
  );

  return (
    <header style={style} data-uk-sticky>
      <div className='uk-grid uk-grid-collapse uk-flex-middle'>
        {iconWrapper}
        {mainContentWrapper}
      </div>
    </header>
  );
};

export default Header;
