import classNames from 'classnames';

import * as theme from '../theme';
import { Icon } from './Icon';

const baseStyle = {
  color: theme.colors.primaryColor,
};

export const Loader = ({ small, className, style }) => (
  <Icon
    className={classNames(
      'loader',
      'fa-spin',
      !small ? 'fa-2x' : null,
      className
    )}
    name='circle-o-notch'
    style={style || baseStyle}
  />
);
