import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,
  year: null,
  cardinality: null,
};

const mapper = {
  [actionTypes.FETCH_NEW_CARDINALITY]: reducerActions.setNewCardinality,
};

export default reducer(mapper, initialState);
