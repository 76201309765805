import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,

  clubManagers: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.SHOW_CLUB_MANAGERS_FETCH_LOADER]: reducerActions.showLoader,
  [actionTypes.HIDE_CLUB_MANAGERS_FETCH_LOADER]: reducerActions.hideLoader,
  [actionTypes.CLUB_MANAGERS_FETCHED]: reducerActions.clubManagersFetched,
  [actionTypes.CLUB_MANAGERS_FETCH_FAILED]:
    reducerActions.clubManagersFetchFailed,
  [actionTypes.CLUB_MANAGER_APPROVED]: reducerActions.clubManagerApproved,
  [actionTypes.CLUB_MANAGER_APPROVAL_FAILED]:
    reducerActions.clubManagerApprovalFailed,
  [actionTypes.CLUB_MANAGER_REJECTED]: reducerActions.clubManagerRejected,
  [actionTypes.CLUB_MANAGER_REJECTION_FAILED]:
    reducerActions.clubManagerRejectionFailed,
};

export default reducer(mapper, initialState);
