import { Button, TextInput } from 'lib';
import { useEffect, useState } from 'react';

export const OfferCategoryForm = (props) => {
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [parent_id, setParentId] = useState('');
  const [description, setDescription] = useState('');
  const [weight, setWeight] = useState(0);

  useEffect(() => {
    if (props.category) {
      setId(props.category.id);
      setName(props.category.name);
      setParentId(props.category.parent_id);
      setDescription(props.category.description);
      setWeight(props.category.weight);
    }
  }, [props.category]);

  const isValid = () => name !== '';

  const { categories } = props;

  return (
    <form
      className='offer-category-form'
      onSubmit={(event) => {
        event.preventDefault();
        props.onSave({
          id,
          name,
          parent_id,
          description,
          weight,
        });
      }}
    >
      <h3>{id ? 'Modifica categoria' : 'Nuova categoria'}</h3>
      <div className='uk-margin-small-bottom'>
        <select
          value={parent_id || ''}
          onChange={(event) => setParentId(event.target.value)
          }
          className='uk-width-1-1 uk-margin-small-bottom'
        >
          <option value=''>- Categoria padre -</option>
          {categories.map((category) => (
            <option key={`parent-${category.id}`} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <TextInput
        placeholder='nome'
        value={name}
        onChange={(event) => setName(event.target.value)
        }
        className='uk-margin-small-bottom'
      />
      <TextInput
        placeholder='descrizione'
        value={description}
        onChange={(event) => setDescription(event.target.value)
        }
        className='uk-margin-small-bottom'
      />
      Peso
      <input
        type='number'
        value={weight}
        onChange={(event) => setWeight(parseInt(event.target.value, 10))
        }
      />
      <div className='actions'>
        <Button disabled={!isValid()}>
          {id ? 'Aggiorna' : 'Salva'}
        </Button>
      </div>
    </form>
  );

};
