import { Button } from 'lib';
import { useEffect, useState } from 'react';

export const EditAdsenseForm = (props) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  const isValid = () => name !== '';

  const save = (event) => {
    event.preventDefault();
    props.onUpdate(props.id, { name: name });
  };

  return (
    <form>
      <p>
        <strong>Slot:</strong>
        <input
          type='text'
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </p>
      <Button disabled={!isValid()} onClick={save}>
        Salva
      </Button>
    </form>
  );

};
