import update from 'immutability-helper';

export const bannersFetched = (state, action) => {
  const { total_pages, page, data } = action.payload;
  return update(state, {
    totalPages: { $set: total_pages },
    page: { $set: page },
    banners: {
      $set: data,
    },
  });
};

export const bannerCreated = (state, action) => {
  const banners = [action.payload, ...state.banners];
  return update(state, { banners: { $set: banners } });
};

export const bannerDeleted = (state, action) => {
  const banners = state.banners.filter(
    (banner) => banner.id !== action.payload.id
  );
  return update(state, { banners: { $set: banners } });
};

export const bannerFetched = (state, action) => update(state, { banner: { $set: action.payload } });

export const allBannersFetched = (state, action) => update(state, { banners: { $set: action.payload } });
