import update from 'immutability-helper';

export const showLoader = (state) => update(state, { loading: { $set: true } });
export const hideLoader = (state) => update(state, { loading: { $set: false } });

export const updateTasksFetched = (state, action) => update(state, {
    totalPages: { $set: action.totalPages },
    page: { $set: action.page },
    update_tasks: {
      $set: action.update_tasks,
    },
  });

export const updateTasksFetchFailed = (state, action) => update(state, {
    error: {
      $set:
        action.error && action.error.errors
          ? action.error.errors.join('\n')
          : true,
    },
  });
