import { call } from 'api';

export const fetchNewCardinality = (successCallback, errorCallback) => call('cardinality', { method: 'GET' }, successCallback, errorCallback);

export const save = (year, cardinality, successCallback, errorCallback) => call(
  'cardinality/save',
  { method: 'POST', body: JSON.stringify({ year, cardinality }) },
  successCallback,
  errorCallback
);
