import API from 'api';

import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

const showLoader = () => ({ type: actionTypes.SHOW_OFFER_CATEGORIES_LOADER });
const hideLoader = () => ({ type: actionTypes.HIDE_OFFER_CATEGORIES_LOADER });

export const fetchOfferCategories = (shouldShowLoader = true) => (dispatch) => {
  if (shouldShowLoader) dispatch(showLoader());

  API.offerCategories.index(
    (response) => {
      dispatch(offerCategoriesFetched(response));
      if (shouldShowLoader) dispatch(hideLoader());
    },
    (error) => {
      dispatch(offerCategoriesFetchFailed(error));
      if (shouldShowLoader) dispatch(hideLoader());
    }
  );
};

const offerCategoriesFetched = (offerCategories) => ({
  type: actionTypes.OFFER_CATEGORIES_FETCHED,
  offerCategories,
});
const offerCategoriesFetchFailed = (error) => ({
  type: actionTypes.OFFER_CATEGORIES_FETCH_FAILED,
  error,
});

export const createOfferCategory = (category) => (dispatch) => {
  UIkit.notification('Salvataggio in corso...');

  API.offerCategories.create(
    category,
    (response) => {
      window.location.href = `/marketplace/${response.offer_category.id}`;
      dispatch(fetchOfferCategories(false));

      UIkit.notification.closeAll();
      UIkit.notification('Categoria creata con successo!', {
        status: 'success',
      });
    },
    (response) => {
      UIkit.notification.closeAll();
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    }
  );
};

export const updateOfferCategory = (category) => (dispatch) => {
  UIkit.notification('Aggiornamento in corso...');

  API.offerCategories.update(
    category,
    () => {
      dispatch(fetchOfferCategories(false));

      UIkit.notification.closeAll();
      UIkit.notification('Categoria aggiornata con successo!', {
        status: 'success',
      });
    },
    (response) => {
      UIkit.notification.closeAll();
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    }
  );
};

export const deleteOfferCategory = (category) => (dispatch) => {
  UIkit.notification('Eliminazione in corso...');

  API.offerCategories.deleteOfferCategory(
    category,
    () => {
      dispatch(fetchOfferCategories(false));

      UIkit.notification.closeAll();
      UIkit.notification('Categoria eliminata con successo!', {
        status: 'success',
      });
    },
    (response) => {
      UIkit.notification.closeAll();
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    }
  );
};
