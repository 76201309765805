import classNames from 'classnames';
import { Button, Icon } from 'lib';

export const PaginationBar = ({
  totalPages,
  onPreviousPageClick,
  onNextPageClick,
  currentPage,
  onPageClick,
}) => {
  const renderPage = (pageNumber) => (
    <div
      key={pageNumber}
      className={classNames(
        'page uk-width-auto',
        currentPage === pageNumber ? 'current' : null
      )}
    >
      <span
        className={`numbers-${pageNumber.toString().length}`}
        onClick={() => onPageClick(pageNumber)}
      >
        {pageNumber}
      </span>
    </div>
  );

  const renderPages = (current, count) => {
    const pages = [];

    const PAGES_BEFORE = 5;
    const PAGES_AFTER = 4;
    const MAX_PAGES = PAGES_BEFORE + 1 + PAGES_AFTER;

    const first = current - PAGES_BEFORE > 0 ? current - PAGES_BEFORE : 1;
    let last = current + PAGES_AFTER;
    if (last < MAX_PAGES) last = MAX_PAGES;
    if (count < MAX_PAGES || last > count) last = count;

    for (let page = first; page <= last; page++) {
      pages.push(renderPage(page));
    }

    return pages;
  };

  return (
    <div className='pagination-bar uk-grid uk-grid-collapse uk-margin-medium-top uk-margin-medium-bottom uk-width-auto uk-flex-center'>
      {currentPage === 1 ? null : (
        <div
          className='previous-page-button uk-width-auto'
          onClick={onPreviousPageClick}
        >
          <Button>
            <Icon name='angle-left' />
            Precedente
          </Button>
        </div>
      )}

      <div className='pages uk-grid uk-grid-collapse uk-width-auto uk-flex-middle'>
        {renderPages(currentPage, totalPages)}
      </div>

      {currentPage >= totalPages ? null : (
        <div
          className='next-page-button uk-width-auto'
          onClick={onNextPageClick}
        >
          <Button>
            Successiva
            <Icon name='angle-right' className='uk-margin-small-left' />
          </Button>
        </div>
      )}
    </div>
  );
};
