import * as theme from '../theme';

const style = {
  width: '60px',
  height: '100vh',
  backgroundColor: theme.colors.primaryColor,
  paddingLeft: theme.sizes.gutter.xs,
  paddingTop: theme.sizes.gutter.xs,
  paddingBottom: theme.sizes.gutter.xs,
  boxSizing: 'border-box',
};

const MainMenu = ({ children }) => (
  <nav className='main-menu uk-inline' style={style} data-uk-sticky>
    {children}
  </nav>
);

export default MainMenu;
