import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Wrapper,
  Loader,
  Header,
  WorkspaceContent,
  SecondaryButton,
  Table,
  Tr,
  Th,
  Td,
  Icon,
} from 'lib';
import { Page } from 'ui/elements';

import {
  fetchOfferCategories,
  createOfferCategory,
  updateOfferCategory,
  deleteOfferCategory,
} from 'store/offerCategories/actions';
import { OfferCategoryForm } from '../components/index';

import OfferCategories from 'helpers/OfferCategories';

export const MarketplacePage = (props) => {
  const { categoryId } = useParams();
  useEffect(() => {
    props.fetch();
    //eslint-disable-next-line
  }, []);

  const renderError = () => <p>errore</p>;

  const handleSave = (category) => category.id ? props.update(category) : props.create(category);

  const renderActions = (category) => (
    <Wrapper>
      <Link to={`/marketplace/${category.id}`}>
        <SecondaryButton>
          <Icon name='pencil' />
        </SecondaryButton>
      </Link>
      {category.children.length === 0 && category.offers_number === 0 && (
        <SecondaryButton
          onClick={() => {
            if (
              window.confirm('Sei sicuro di voler eliminare questa categoria?')
            ) {
              props.deleteCategory(category.id);
            }
          }}
        >
          <Icon name='trash' />
        </SecondaryButton>
      )}
    </Wrapper>
  );

  const renderCategoryRow = (category) => (
    <Tr key={`category-${category.id}`}>
      <Td className='uk-table-shrink uk-text-center'>{category.id}</Td>
      <Td colSpan='2'>
        {category.name}
        <br />
        <small>{category.description}</small>
      </Td>
      <Td className='uk-table-shrink'>{category.weight}</Td>
      <Td className='uk-table-shrink uk-text-center' />
      <Td className='uk-table-shrink uk-text-nowrap uk-text-center'>
        {renderActions(category)}
      </Td>
    </Tr>
  );

  const renderSubCategoryRow = (category) => (
    <Tr key={`category-${category.id}`}>
      <Td className='uk-table-shrink uk-text-center'>{category.id}</Td>
      <Td className='uk-table-shrink uk-text-nowrap uk-text-center'>&ndash;</Td>
      <Td>
        {category.name}
        <br />
        <small>{category.description}</small>
      </Td>
      <Td className='uk-table-shrink'>{category.weight}</Td>
      <Td className='uk-table-shrink uk-text-center'>
        {category.offers_number}
      </Td>
      <Td className='uk-table-shrink uk-text-nowrap uk-text-center'>
        {renderActions(category)}
      </Td>
    </Tr>
  );

  const renderList = () => (
    <Table
      className='club-managers-list'
      head={
        <Tr>
          <Th className='uk-table-shrink'>ID</Th>
          <Th className='uk-table-expand uk-text-left' colSpan='2'>
            Nome
          </Th>
          <Th className='uk-table-shrink'>Peso</Th>
          <Th className='uk-table-shrink uk-text-center'>Prodotti</Th>
          <Th className='uk-table-shrink uk-text-center' />
        </Tr>
      }
      rows={props.offerCategories}
      rowRenderer={(category) => (
        <Wrapper key={`wrapper-category-${category.id}`}>
          {renderCategoryRow(category)}
          {category.children.map(renderSubCategoryRow)}
        </Wrapper>
      )}
    />
  );

  const renderForm = () => {
    const category = categoryId
      ? OfferCategories.findById(
        props.offerCategories,
        parseInt(categoryId, 10)
      )
      : null;
    return (
      <OfferCategoryForm
        category={category}
        categories={props.offerCategories}
        onSave={handleSave}
      />
    );
  };

  const { loading, error, offerCategories } = props;

  let content = <Loader />;
  if (!loading) {
    if (error) {
      content = renderError();
    } else {
      if (offerCategories) {
        content = (
          <div className='uk-grid'>
            <div className='uk-width-1-3'>{renderForm()}</div>
            <div className='uk-width-2-3'>{renderList()}</div>
          </div>
        );
      }
    }
  }

  return (
    <Page title='Marketplace' className='marketplace'>
      <Header title='Marketplace' />

      <WorkspaceContent>{content}</WorkspaceContent>
    </Page>
  );

};

const mapStateToProps = (state) => ({ ...state.offerCategories });
const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchOfferCategories()),
  create: (category) => dispatch(createOfferCategory(category)),
  update: (category) => dispatch(updateOfferCategory(category)),
  deleteCategory: (categoryId) => dispatch(deleteOfferCategory(categoryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketplacePage);
