import { Button, Table, Td, Th, Tr } from 'lib';
import { Link } from 'react-router-dom';

export const CustomBanners = ({ banners, deleteBanner }) => (
  <Table
    className='update-tasks-list uk-margin-top'
    head={
      <Tr>
        <Th className='uk-table-shrink'>ID</Th>
        <Th className='uk-table-shrink'>Immagine</Th>
        <Th className='uk-table-shrink'>Destinazione</Th>
        <Th className='uk-table-shrink'>Azioni</Th>
      </Tr>
    }
    rows={banners}
    rowRenderer={(banner) => (
      <Tr key={`update-task-${banner.id}`}>
        <Td className='uk-table-shrink'>{banner.id}</Td>
        <Td>
          <img src={banner.image} alt='Custom Banner' />
        </Td>
        <Td>
          <a
            href={banner.destination}
            target='_blank'
            rel='noopener noreferrer'
          >
            {banner.destination}
          </a>
        </Td>
        <Td>
          <Link to={`/custom-banner/${banner.id}/modifica`}>Modifica</Link>
          <Button
            onClick={() => {
              if (
                window.confirm('Sei sicuro di voler eliminare questo banner?')
              )
                deleteBanner(banner.id);
            }}
          >
            Elimina
          </Button>
        </Td>
      </Tr>
    )}
  />
);
