import { SecondaryMenu, SecondaryMenuSection } from 'lib';
import { NavLink } from 'react-router-dom';

export const UsersMenu = () => (
  <SecondaryMenu>
    <SecondaryMenuSection title='Gestione utenti'>
      <NavLink to='/utenti'>
        Tutti
      </NavLink>
      <br />
      <NavLink to='/utenti/gestori-circoli'>Gestori circoli</NavLink>
    </SecondaryMenuSection>
  </SecondaryMenu>
);
