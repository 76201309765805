import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,

  loggedIn: false,
  loggedOut: false,

  user: null,
};

const mapper = {
  [actionTypes.SHOW_AUTHENTICATION_LOADER]: reducerActions.showLoader,
  [actionTypes.HIDE_AUTHENTICATION_LOADER]: reducerActions.hideLoader,

  [actionTypes.LOGIN_SUCCEDED]: reducerActions.loginSucceded,
  [actionTypes.LOGIN_FAILED]: reducerActions.loginFailed,

  [actionTypes.LOGOUT_SUCCEDED]: reducerActions.logoutSucceded,
  [actionTypes.LOGOUT_FAILED]: reducerActions.logoutFailed,
};

export default reducer(mapper, initialState);
