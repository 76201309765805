import { useEffect } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { fetchBanner, updateBanner } from 'store/banners/actions';
import { EditBannerForm } from 'ui/components';
import { getAllCustomBanners } from 'store/customBanners/actions';
import { getAllAdsense } from 'store/adsense/actions';
import { useParams } from 'react-router-dom';

export const EditBannerPage = (props) => {
  const { id } = useParams();

  useEffect(() => {
    props.fetch(id);
    props.getAllCustomBanners();
    props.getAllAdsense();
    //eslint-disable-next-line
  }, []);

  return (
    <Page title='Modifica Banner' className='edit-banner'>
      <Header title='Modifica Banner' />
      <WorkspaceContent>
        <EditBannerForm
          banner={props.banner}
          onUpdate={props.update}
          customBanners={props.customBanners}
          adsenses={props.adsenses}
        />
      </WorkspaceContent>
    </Page>
  );

};

const mapStateToProps = (state) => ({
  ...state.banners,
  customBanners: state.customBanners.banners,
  adsenses: state.adsense.adsenses,
});
const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(fetchBanner(id)),
  update: (id, data) => dispatch(updateBanner(id, data)),
  getAllCustomBanners: () => dispatch(getAllCustomBanners()),
  getAllAdsense: () => dispatch(getAllAdsense()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBannerPage);
