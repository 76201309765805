export const SHOW_AUTHENTICATION_LOADER = 'SHOW_AUTHENTICATION_LOADER';
export const HIDE_AUTHENTICATION_LOADER = 'HIDE_AUTHENTICATION_LOADER';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEDED = 'LOGIN_SUCCEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCEDED = 'LOGOUT_SUCCEDED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
