import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,
  banner: null,
  banners: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.BANNER_CREATED]: reducerActions.bannerCreated,
  [actionTypes.BANNERS_FETCHED]: reducerActions.bannersFetched,
  [actionTypes.BANNER_DELETED]: reducerActions.bannerDeleted,
  [actionTypes.BANNER_FETCHED]: reducerActions.bannerFetched,
};

export default reducer(mapper, initialState);
