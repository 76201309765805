export const SHOW_USERS_FETCH_LOADER = 'SHOW_USERS_FETCH_LOADER';
export const HIDE_USERS_FETCH_LOADER = 'HIDE_USERS_FETCH_LOADER';

export const FETCH_USERS = 'FETCH_USERS';
export const USERS_FETCHED = 'USERS_FETCHED';
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';

export const SHOW_SINGLE_USER_LOADER = 'SHOW_SINGLE_USER_LOADER';
export const HIDE_SINGLE_USER_LOADER = 'HIDE_SINGLE_USER_LOADER';

export const SET_HIDDEN_RESULTS = 'SET_HIDDEN_RESULTS';
export const HIDDEN_RESULTS_SET = 'HIDDEN_RESULTS_SET';
export const HIDDEN_RESULTS_SET_FAILED = 'HIDDEN_RESULTS_SET_FAILED';

export const SET_PREMIUM_USER = 'SET_PREMIUM_USER';
export const PREMIUM_USER_SET = 'PREMIUM_USER_SET';
export const PREMIUM_USER_SET_FAILED = 'PREMIUM_USER_SET_FAILED';

export const BAN = 'BAN';
export const BANNED = 'BANNED';
export const BAN_FAILED = 'BAN_FAILED';

export const UNBAN = 'UNBAN';
export const UNBANNED = 'UNBANNED';
export const UNBAN_FAILED = 'UNBAN_FAILED';

export const SET_PREMIUM_EXPIRATION_DATE = 'SET_PREMIUM_EXPIRATION_DATE';
export const PREMIUM_EXPIRATION_DATE_SET = 'PREMIUM_EXPIRATION_DATE_SET';
export const PREMIUM_EXPIRATION_DATE_SET_FAILED = 'PREMIUM_EXPIRATION_DATE_SET_FAILED';
