const style = {
  paddingTop: '50px',
  paddingLeft: '50px',
  paddingRight: '50px',
  paddingBottom: '100px',
};

const WorkspaceContent = ({ children }) => (
  <div className='workspace-content'>
    <div style={style}>{children}</div>
  </div>
);

export default WorkspaceContent;
