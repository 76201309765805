import update from 'immutability-helper';

export const showLoader = (state) => update(state, { loading: { $set: true } });
export const hideLoader = (state) => update(state, { loading: { $set: false } });

export const loginSucceded = (state, action) => update(state, { user: { $set: action.user } });
export const loginFailed = (state, action) => update(state, { error: { $set: action.error.errors.join('\n') } });

export const logoutSucceded = (state) => update(state, { user: { $set: null } });
export const logoutFailed = (state, action) => update(state, { error: { $set: action.error } });
