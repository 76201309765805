import API from 'api';
import * as actionTypes from './actionTypes';

export const fetchPlayersRequests = (page) => (dispatch) => {
  API.playersRequests.all(
    page,
    (response) => {
      dispatch(playersRequestsFetched(response));
    },
    (error) => {
      dispatch(playersRequestsError(error));
    }
  );
};

const playersRequestsFetched = (response) => ({
  type: actionTypes.PLAYERS_REQUESTS_FETCHED,
  payload: response,
});

const playersRequestsError = (error) => ({
  type: actionTypes.PLAYERS_REQUESTS_ERROR,
  payload: error,
});
