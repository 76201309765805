import * as theme from '../theme';

const titleStyle = {
  color: theme.colors.white,
  fontSize: theme.sizes.font.sm,
  textTransform: 'uppercase',
};

export const SecondaryMenuSection = ({
  title,
  children,
  collapsible = false,
}) => (
  <ul data-uk-accordion={`collapsible: ${collapsible}`}>
    <li>
      <a className='uk-accordion-title uk-dark' href='#' style={titleStyle}>
        {title}
      </a>
      <div className='uk-accordion-content'>{children}</div>
    </li>
  </ul>
);
