export default {
  primaryColor: '#4A148C',
  secondaryColor: '#7E57C2',

  lightGrey: '#DDDDDD',
  midLightGrey: '#AAAAAA',
  midDarkGrey: '#888888',
  darkGrey: '#555555',

  white: '#FFFFFF',
  halfWhite: 'rgba(255, 255, 255, 0.5)',

  black: '#000000',
  halfBlack: 'rgba(0, 0, 0, 0.5)',

  yellow: '#FFD600',
  red: '#E53935',
  green: '#00E676',
};
