import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { save } from 'store/cardinality/actions';
import { Button, Loader } from 'lib';

export const RankingForm = (props) => {
  const [cardinality, setCardinality] = useState(props.cardinality || '');
  const [year, setYear] = useState(props.year || '');

  useEffect(() => {
    setYear(props.year);
  }, [props.year]);

  useEffect(() => {
    setCardinality(props.cardinality);
  }, [props.cardinality]);

  const isValid = () => year !== '' && cardinality !== '';

  const handleSave = (event) => {
    event.preventDefault();
    props.save(year, cardinality);
  };

  const renderContent = () => (
    <form onSubmit={() => props.save({
      year,
      cardinality,
    })}>
      <label>Anno</label>
      <input
        type='text'
        value={year}
        onChange={(event) => setYear(event.target.value)
        }
      />
      <label>Cardinalità</label>
      <input
        type='text'
        value={cardinality}
        onChange={(event) => setCardinality(event.target.value)
        }
      />
      <Button
        disabled={!isValid()}
        onClick={handleSave}
        type='submit'
      >
        Crea
      </Button>
    </form>
  );

  const content = (props.loading || !year || !cardinality) ? <Loader /> : renderContent();

  return (
    <div className={classNames('cardinlality-form', props.className)}>
      {content}
    </div>
  );

};

const mapDispatchToProps = (dispatch) => ({
  save: (year, cardinality) => dispatch(save(year, cardinality)),
});

export default connect(null, mapDispatchToProps)(RankingForm);
