import API from 'api';

import UIkit from 'uikit';
import * as actionTypes from './actionTypes';

const showLoader = () => ({ type: actionTypes.SHOW_MATCHES_LOADER });
const hideLoader = () => ({ type: actionTypes.HIDE_MATCHES_LOADER });

export const fetchMatches = (page, shouldShowLoader = true) => (dispatch) => {
  if (shouldShowLoader) dispatch(showLoader());

  API.matches.index(
    page,
    (response) => {
      dispatch(matchesFetched(response.matches));
      if (shouldShowLoader) dispatch(hideLoader());
    },
    (error) => {
      dispatch(matchesFetchFailed(error));
      if (shouldShowLoader) dispatch(hideLoader());
    }
  );
};

const matchesFetched = (matches) => ({
  type: actionTypes.MATCHES_FETCHED,
  matches,
});
const matchesFetchFailed = (error) => ({
  type: actionTypes.MATCHES_FETCH_FAILED,
  error,
});

export const setMatchResult = (matchId, result) => ({
  type: actionTypes.SET_MATCH_RESULT,
  matchId,
  result,
});

export const updateMatch = (match) => () => {
  UIkit.notification('Aggiornamento in corso...');

  API.matches.update(
    match,
    () => {
      UIkit.notification.closeAll();
      UIkit.notification('Partita aggiornata con successo!', {
        status: 'success',
      });
    },
    (response) => {
      UIkit.notification.closeAll();
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    }
  );
};

export const deleteMatch = (match, page) => (dispatch) => {
  UIkit.notification('Eliminazione in corso...');

  API.matches.deleteMatch(
    match,
    () => {
      dispatch(fetchMatches(page, false));

      UIkit.notification.closeAll();
      UIkit.notification('Partita eliminata con successo!', {
        status: 'success',
      });
    },
    (response) => {
      UIkit.notification.closeAll();
      const errorMessage = Object.values(response.errors)
        .reduce((result, error) => result.concat(error), [])
        .join('<br />');
      UIkit.notification(errorMessage, { status: 'danger' });
    }
  );
};
