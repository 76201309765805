import update from 'immutability-helper';

export const playersRequestsFetched = (state, action) => {
  const { total_pages, page, data } = action.payload;
  return update(state, {
    totalPages: { $set: total_pages },
    page: { $set: page },
    playersRequests: {
      $set: data,
    },
  });
};

export const playersRequestsError = (state, action) => update(state, { error: action.payload });
