import { call } from 'api';

export const index = (page = 1, successCallback, errorCallback) => call(`users?page=${page}`, { method: 'GET' }, successCallback, errorCallback);

export const search = (q, page = 1, successCallback, errorCallback) => call(`users/search?q=${q}&page=${page}`, { method: 'GET' }, successCallback, errorCallback);

export const setHiddenResults = (userId, successCallback, errorCallback) => call(
  `users/${userId}/mark-as-hidden-results`,
  { method: 'PUT' },
  successCallback,
  errorCallback
);

export const setPremiumUser = (userId, successCallback, errorCallback) => call(
  `users/${userId}/mark-as-premium-user`,
  { method: 'PUT' },
  successCallback,
  errorCallback
);

export const ban = (userId, notes, successCallback, errorCallback) => call(
  `users/${userId}/ban`,
  { method: 'PUT', body: JSON.stringify({ ban_notes: notes }) },
  successCallback,
  errorCallback
);

export const unban = (userId, successCallback, errorCallback) => call(
  `users/${userId}/unban`,
  { method: 'PUT' },
  successCallback,
  errorCallback
);

export const setPremiumExpirationDate = (
  userId,
  date,
  successCallback,
  errorCallback
) => call(
  `users/${userId}/set-premium-expiration-date`,
  { method: 'PUT', body: JSON.stringify({ date: date }) },
  successCallback,
  errorCallback
);
