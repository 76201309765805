import API from 'api';
import * as actionTypes from './actionTypes';

const showLoader = () => ({ type: actionTypes.SHOW_USERS_FETCH_LOADER });
const hideLoader = () => ({ type: actionTypes.HIDE_USERS_FETCH_LOADER });

export const fetchUsers = (page) => (dispatch) => {
  dispatch(showLoader());

  API.users.index(
    page,
    (response) => {
      dispatch(usersFetched({ ...response }));
      dispatch(hideLoader());
    },
    (error) => {
      dispatch(usersFetchFailed(error));
      dispatch(hideLoader());
    }
  );
};

export const searchUsers = (q, page) => (dispatch) => {
  if (!q) return dispatch(fetchUsers(page));
  API.users.search(
    q,
    page,
    (response) => {
      dispatch(usersFetched({ ...response }));
    },
    (error) => {
      dispatch(usersFetchFailed(error));
    }
  );
};

const usersFetched = ({ total_pages, users, page }) => ({
  type: actionTypes.USERS_FETCHED,
  totalPages: total_pages,
  page: page,
  users,
});
const usersFetchFailed = (error) => ({
  type: actionTypes.USERS_FETCH_FAILED,
  error,
});

const showUserLoader = (userId, loader) => ({
  type: actionTypes.SHOW_SINGLE_USER_LOADER,
  userId,
  loader,
});
const hideUserLoader = (userId, loader) => ({
  type: actionTypes.HIDE_SINGLE_USER_LOADER,
  userId,
  loader,
});

export const setHiddenResults = (userId) => (dispatch) => {
  dispatch(showUserLoader(userId, 'hiddenResults'));

  API.users.setHiddenResults(
    userId,
    () => {
      dispatch(hiddenResultsSet(userId));
      dispatch(hideUserLoader(userId, 'hiddenResults'));
    },
    (error) => {
      dispatch(hiddenResultsSetFailed(error));
      dispatch(hideUserLoader(userId, 'hiddenResults'));
    }
  );
};

const hiddenResultsSet = (userId) => ({
  type: actionTypes.HIDDEN_RESULTS_SET,
  userId,
});
const hiddenResultsSetFailed = (error) => ({
  type: actionTypes.HIDDEN_RESULTS_SET_FAILED,
  error,
});

export const setPremiumUser = (userId) => (dispatch) => {
  dispatch(showUserLoader(userId, 'premiumUser'));

  API.users.setPremiumUser(
    userId,
    () => {
      dispatch(premiumUserSet(userId));
      dispatch(hideUserLoader(userId, 'premiumUser'));
    },
    (error) => {
      dispatch(premiumUserSetFailed(error));
      dispatch(hideUserLoader(userId, 'premiumUser'));
    }
  );
};

const premiumUserSet = (userId) => ({
  type: actionTypes.PREMIUM_USER_SET,
  userId,
});
const premiumUserSetFailed = (error) => ({
  type: actionTypes.PREMIUM_USER_SET_FAILED,
  error,
});

export const ban = (userId, notes) => (dispatch) => {
  dispatch(showUserLoader(userId, 'isBanned'));

  API.users.ban(
    userId,
    notes,
    () => {
      dispatch(banned(userId, notes));
      dispatch(hideUserLoader(userId, 'isBanned'));
    },
    (error) => {
      dispatch(banFailed(error));
      dispatch(hideUserLoader(userId, 'isBanned'));
    }
  );
};

const banned = (userId, notes) => ({
  type: actionTypes.BANNED,
  userId,
  notes,
});
const banFailed = (error) => ({
  type: actionTypes.BAN_FAILED,
  error,
});

export const unban = (userId) => (dispatch) => {
  dispatch(showUserLoader(userId, 'isBanned'));

  API.users.unban(
    userId,
    () => {
      dispatch(unbanned(userId));
      dispatch(hideUserLoader(userId, 'isBanned'));
    },
    (error) => {
      dispatch(unbanFailed(error));
      dispatch(hideUserLoader(userId, 'isBanned'));
    }
  );
};

const unbanned = (userId) => ({
  type: actionTypes.UNBANNED,
  userId,
});
const unbanFailed = (error) => ({
  type: actionTypes.UNBAN_FAILED,
  error,
});

export const setPremiumExpirationDate = (userId, date) => (dispatch) => {
  dispatch(showUserLoader(userId, 'premiumExpirationDate'));
  API.users.setPremiumExpirationDate(
    userId,
    new Date(date),
    () => {
      dispatch(premiumExpirationDateSet(userId, date));
      dispatch(hideUserLoader(userId, 'premiumExpirationDate'));
    },
    (error) => {
      dispatch(premiumExpirationDateSetFailed(error));
      dispatch(hideUserLoader(userId, 'premiumExpirationDate'));
    }
  );
};

const premiumExpirationDateSet = (userId, premiumExpirationDate) => ({
  type: actionTypes.PREMIUM_EXPIRATION_DATE_SET,
  userId,
  premiumExpirationDate,
});
const premiumExpirationDateSetFailed = (error) => ({
  type: actionTypes.PREMIUM_EXPIRATION_DATE_SET_FAILED,
  error,
});
