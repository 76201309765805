export const SHOW_OFFER_CATEGORIES_LOADER = 'SHOW_OFFER_CATEGORIES_LOADER';
export const HIDE_OFFER_CATEGORIES_LOADER = 'HIDE_OFFER_CATEGORIES_LOADER';

export const FETCH_OFFER_CATEGORIES = 'FETCH_OFFER_CATEGORIES';
export const OFFER_CATEGORIES_FETCHED = 'OFFER_CATEGORIES_FETCHED';
export const OFFER_CATEGORIES_FETCH_FAILED = 'OFFER_CATEGORIES_FETCH_FAILED';

export const CREATE_OFFER_CATEGORY = 'CREATE_OFFER_CATEGORY';
export const UPDATE_OFFER_CATEGORY = 'UPDATE_OFFER_CATEGORY';
export const DELETE_OFFER_CATEGORY = 'DELETE_OFFER_CATEGORY';
