import classNames from 'classnames';

import * as theme from '../theme';

const baseStyle = {
  paddingTop: theme.sizes.gutter.xs,
  paddingRight: theme.sizes.gutter.sm,
  paddingBottom: theme.sizes.gutter.xs,
  paddingLeft: theme.sizes.gutter.sm,
  color: theme.colors.black,
};

export const Td = ({ className, style, children, ...otherProps }) => (
  <td
    className={classNames('td', className)}
    style={{ ...baseStyle, ...style }}
    {...otherProps}
  >
    {children}
  </td>
);
