import update from 'immutability-helper';

export const showLoader = (state) => update(state, { loading: { $set: true } });
export const hideLoader = (state) => update(state, { loading: { $set: false } });

export const usersFetched = (state, action) => update(state, {
  totalPages: { $set: action.totalPages },
  page: { $set: action.page },
  users: {
    $set: action.users.map((user) => ({
      ...user, loaders: {
        isBanned: false,
        hiddenResults: false,
        premiumUser: false,
        premiumExpirationDate: false,
      },
    })
    ),
  },
});

export const usersFetchFailed = (state, action) => update(state, {
  error: {
    $set:
      action.error && action.error.errors
        ? action.error.errors.join('\n')
        : true,
  },
});

export const showUserLoader = (state, action) => update(state, {
  users: {
    $set: state.users.map((user) => user.id === action.userId
      ? { ...user, loaders: { ...user.loaders, [action.loader]: true } }

      : user
    ),
  },
});

export const hideUserLoader = (state, action) => update(state, {
  users: {
    $set: state.users.map((user) => user.id === action.userId
      ? { ...user, loaders: { ...user.loaders, [action.loader]: false } }
      : user
    ),
  },
});

export const hiddenResultsSet = (state, action) => update(state, {
  users: {
    $set: state.users.map((user) => user.id === action.userId
      ? { ...user, hidden_results: !user.hidden_results }
      : user
    ),
  },
});

export const hiddenResultsSetFailed = (state, action) => usersFetchFailed(state, action);

export const premiumUserSet = (state, action) => update(state, {
  users: {
    $set: state.users.map((user) => user.id === action.userId
      ? {
        ...user,
        premium: !user.premium,
      }
      : user
    ),
  },
});

export const premiumUserSetFailed = (state, action) => usersFetchFailed(state, action);

export const banned = (state, action) => update(state, {
  users: {
    $set: state.users.map((user) => user.id === action.userId
      ? { ...user, is_banned: true, ban_notes: action.notes }

      : user
    ),
  },
});

export const banFailed = (state, action) => usersFetchFailed(state, action);

export const unbanned = (state, action) => update(state, {
  users: {
    $set: state.users.map((user) => user.id === action.userId
      ? { ...user, is_banned: false, ban_notes: null }

      : user
    ),
  },
});

export const unbanFailed = (state, action) => usersFetchFailed(state, action);

export const premiumExpirationDateSet = (state, action) => update(state, {
  users: {
    $set: state.users.map((user) => user.id === action.userId
      ? { ...user, premium_expiration: action.premiumExpirationDate }
      : user
    ),
  },
});

export const premiumExpirationDateSetFailed = (state, action) => usersFetchFailed(state, action);
