export const SHOW_MATCHES_LOADER = 'SHOW_MATCHES_LOADER';
export const HIDE_MATCHES_LOADER = 'HIDE_MATCHES_LOADER';

export const FETCH_MATCHES = 'FETCH_MATCHES';
export const MATCHES_FETCHED = 'MATCHES_FETCHED';
export const MATCHES_FETCH_FAILED = 'MATCHES_FETCH_FAILED';

export const UPDATE_MATCH = 'UPDATE_MATCH';
export const DELETE_MATCH = 'DELETE_MATCH';

export const SET_MATCH_RESULT = 'SET_MATCH_RESULT';
