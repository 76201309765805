import { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { save } from 'store/banners/actions';
import { Button, Loader } from 'lib';
import Select from 'react-select';

const BANNERABLE_TYPES = [
  // 'ADSENSE',
  'TENNISTALKER',
];

class BannerForm extends PureComponent {
  //TODO comp diventa stateless
  state = {
    slot: '',
    bannerable_type: BANNERABLE_TYPES[0],
    bannerable_id: [],
  };

  isValid() {
    const { slot, bannerable_type, bannerable_id } = this.state;
    return slot && BANNERABLE_TYPES.includes(bannerable_type) && bannerable_id;
  }

  handleSave = (event) => {
    event.preventDefault();
    this.props.save(this.state, () => {
      this.setState({
        slot: '',
        bannerable_type: BANNERABLE_TYPES[0],
        bannerable_id: [],
      });
    });
  };

  getOptions = (arr) => {
    const result = [];
    arr && arr.forEach((elem) => {
      const obj = {};
      obj.value = elem.id;
      obj.label = `${elem.id} ${elem.destination}`;
      if (elem)
        result.push(obj);
    });
    return result;
  };

  renderContent = () => {
    const { slot, bannerable_type } = this.state;
    const { customBanners, adsenses } = this.props;
    let banners =
      (bannerable_type === 'ADSENSE' ? adsenses : customBanners) || [];
    banners = ['', ...banners];
    return (
      <form onSubmit={() => this.props.save(this.state)}>
        <p>
          <label>Slot</label>
          <input
            type='text'
            value={slot}
            onChange={(event) => this.setState({ slot: event.target.value })}
          />
        </p>
        <p>
          <label>Tipo</label>
          <select
            value={bannerable_type}
            onChange={(event) => this.setState({
              bannerable_type: event.target.value,
              bannerable_id: [],
            })
            }
          >
            {BANNERABLE_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </p>
        <label>Banner</label>
        <Select
          isMulti
          name='selectedCategories'
          options={this.getOptions(banners)}
          // value={bannerable_id}
          className='uk-width-2-3 uk-display-inline-block'
          classNamePrefix='select'
          placeholder='Seleziona i Banner'
          onChange={(selectedOption) => {
            if (!selectedOption) {
              this.setState({ bannerable_id: [] });
            }
            else {
              const arr = selectedOption.map((elem) => elem.value);
              this.setState({
                bannerable_id: arr,
              });
            }
          }
          }
        />

        <div>
          <Button
            disabled={!this.isValid()}
            onClick={this.handleSave}
            type='submit'
            className='uk-margin-top'
          >
            Salva
          </Button>
        </div>
      </form>
    );
  };

  render() {
    const content = this.props.loading ? <Loader /> : this.renderContent();

    return (
      <div className={classNames('banner-form', this.props.className)}>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.banners });
const mapDispatchToProps = (dispatch) => ({
  save: (data, afterSaveCallback) => dispatch(save(data, afterSaveCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerForm);
