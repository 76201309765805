import { Button } from 'lib';
import { useEffect, useState } from 'react';
import Select from 'react-select';

const BANNERABLE_TYPES = [
  // 'ADSENSE',
  'TENNISTALKER',
];

export const EditBannerForm = (props) => {
  const [id, setId] = useState('');
  const [slot, setSlot] = useState('');
  const [bannerable_type, setBannerableType] = useState('');
  const [bannerable_id, setBannerableId] = useState([]);

  useEffect(() => () => {
    setSlot('');
    setBannerableType('');
    setBannerableId([]);
  }, []);

  useEffect(() => {
    if (props.banner) {
      setId(props.banner.id);
      setSlot(props.banner.slot);
      setBannerableType(props.banner.type);
      setBannerableId(props.banner.bannerable_id);
      if (props.banner?.custom_banners) {
        setBannerableId(props.banner.custom_banners.map((banner) => banner.id));
      }
    }
  }, [props.banner]);

  const getOptions = (arr) => {
    const result = [];
    arr && arr.forEach((elem) => {
      const obj = {};
      obj.value = elem.id;
      obj.label = `${elem.id} ${elem.destination}`;
      if (elem)
        result.push(obj);
    });
    return result;
  };

  const getValue = (banner, options) => {
    if (!banner) return;
    if (Number.isInteger(banner)) return;
    return options.filter((elem) => banner.includes(elem.value));
  };

  const isValid = () => slot && BANNERABLE_TYPES.includes(bannerable_type) && bannerable_id;

  const save = (event) => {
    event.preventDefault();
    props.onUpdate(id, {
      id,
      slot,
      bannerable_type,
      bannerable_id,
    });
  };

  const { customBanners, adsenses } = props;

  let banners =
    (bannerable_type === 'ADSENSE' ? adsenses : customBanners) || [];
  banners = ['', ...banners];

  return (
    <form>
      <p>
        <strong>Slot:</strong>
        <input
          type='text'
          value={slot}
          onChange={(event) => setSlot(event.target.value)}
        />
      </p>
      <p>
        <strong>Tipo</strong>
        <select
          value={bannerable_type}
          onChange={(event) => {
            setBannerableType(event.target.value);
            setBannerableId([]);
          }
          }
        >
          {BANNERABLE_TYPES.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </p>

      <strong>Banner</strong>
      <Select
        isMulti
        name='selectedCategories'
        options={getOptions(banners)}
        value={getValue(bannerable_id, getOptions(banners))}
        className='uk-width-2-3 uk-display-inline-block'
        classNamePrefix='select'
        placeholder='Seleziona i Banner'
        onChange={(selectedOption) => {
          if (!selectedOption) {
            setBannerableId([]);
          }
          else {
            const arr = selectedOption.map((elem) => elem.value);
            setBannerableId(arr);

          }
        }
        }
      />
      <div>
        <Button
          disabled={!isValid()}
          onClick={save}
          className='uk-margin-top'
        >
          Salva
        </Button>
      </div>
    </form>
  );

};
