import * as authentication from './authentication';
import * as clubManagers from './clubManagers';
import * as matches from './matches';
import * as offerCategories from './offerCategories';
import * as users from './users';
import * as updateTasks from './updateTasks';
import * as customBanners from './customBanners';
import * as adsense from './adsense';
import * as banners from './banners';
import * as reports from './reports';
import * as cardinality from './cardinality';
import * as playersRequests from './playersRequests';
import * as membershipCodes from './membershipCodes';

export default {
  authentication,
  clubManagers,
  offerCategories,
  matches,
  users,
  updateTasks,
  customBanners,
  adsense,
  banners,
  reports,
  cardinality,
  playersRequests,
  membershipCodes,
};

export * from './call';
