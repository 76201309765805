export const SHOW_CLUB_MANAGERS_FETCH_LOADER = 'SHOW_CLUB_MANAGERS_FETCH_LOADER';
export const HIDE_CLUB_MANAGERS_FETCH_LOADER = 'HIDE_CLUB_MANAGERS_FETCH_LOADER';

export const FETCH_CLUB_MANAGERS = 'FETCH_CLUB_MANAGERS';
export const CLUB_MANAGERS_FETCHED = 'CLUB_MANAGERS_FETCHED';
export const CLUB_MANAGERS_FETCH_FAILED = 'CLUB_MANAGERS_FETCH_FAILED';

export const APPROVE_CLUB_MANAGER = 'APPROVE_CLUB_MANAGER';
export const CLUB_MANAGER_APPROVED = 'CLUB_MANAGER_APPROVED';
export const CLUB_MANAGER_APPROVAL_FAILED = 'CLUB_MANAGER_APPROVAL_FAILED';

export const REJECT_CLUB_MANAGER = 'REJECT_CLUB_MANAGER';
export const CLUB_MANAGER_REJECTED = 'CLUB_MANAGER_REJECTED';
export const CLUB_MANAGER_REJECTION_FAILED = 'CLUB_MANAGER_REJECTION_FAILED';
