import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { create, deletes, fetch, show, update } from 'api/membershipCodes';
import uikit from 'uikit';

export const fetchMembershipCodes = createAsyncThunk<any, string>(
  'fetchMembershipCodes',
  async (page, { rejectWithValue }) => {
    const response = await fetch(page, undefined, (error) => rejectWithValue(error));
    return response;
  },
);

export const createMembershipCode = createAsyncThunk<any, string>(
  'createMembershipCode',
  async (data, { rejectWithValue }) => {
    const response = await create({ premium_token: data }, undefined, (error) => rejectWithValue(error));
    return response;
  },
);

export const deleteMembershipCode = createAsyncThunk<any, string>(
  'deleteMembershipCode',
  async (id, { rejectWithValue }) => {
    const response = await deletes(id, undefined, (error) => rejectWithValue(error));
    return response;
  },
);

export const showMembershipCode = createAsyncThunk<any, string>(
  'showMembershipCode',
  async (id, { rejectWithValue }) => {
    const response = await show(id, undefined, (error) => rejectWithValue(error));
    return response;
  },
);

export const updateMembershipCode = createAsyncThunk<any, string>(
  'updateMembershipCode',
  async (callData: any, { rejectWithValue }) => {
    const { id, data } = callData;
    const response = await update(id, { premium_token: data }, undefined, (error) => rejectWithValue(error));
    return response;
  },
);

export const membershipCodesSlice = createSlice({
  name: 'membershipCodes',
  initialState: {
    membershipCodes: [],
    membershipCode: undefined,
    loading: false,
    page: 1,
    totalPages: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMembershipCodes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMembershipCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.membershipCodes = action.payload.data;
      state.page = action.payload.page;
      state.totalPages = action.payload.total_pages;
    });
    builder.addCase(fetchMembershipCodes.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createMembershipCode.fulfilled, (state, action) => {
      state.loading = false;
      const membershipCodes = [action.payload.premium_token, ...state.membershipCodes];
      state.membershipCodes = membershipCodes;
      uikit.notification('Token inserito con successo', {
        status: 'success',
      });
    });
    builder.addCase(createMembershipCode.rejected, (state) => {
      state.loading = false;
      uikit.notification('Errore nella creazione token', {
        status: 'danger',
      });
    });
    builder.addCase(deleteMembershipCode.fulfilled, (state, action) => {
      state.loading = false;
      const membershipCodes = state.membershipCodes.filter(
        (membershipCode) => membershipCode.id !== action.payload.premium_token.id,
      );
      state.membershipCodes = membershipCodes;
      uikit.notification('Token cancellato con successo', {
        status: 'success',
      });
    });
    builder.addCase(deleteMembershipCode.rejected, (state) => {
      state.loading = false;
      uikit.notification('Errore nella cancellazione token', {
        status: 'danger',
      });
    });
    builder.addCase(showMembershipCode.fulfilled, (state, action) => {
      state.loading = false;
      state.membershipCode = action.payload;
    });
    builder.addCase(showMembershipCode.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateMembershipCode.fulfilled, (state) => {
      state.loading = false;
      uikit.notification('Token aggiornato con successo', {
        status: 'success',
      });
      window.location.href = '/codici-membership';
    });
    builder.addCase(updateMembershipCode.rejected, (state) => {
      state.loading = false;
      uikit.notification('Errore durante aggiornamento token', {
        status: 'danger',
      });
    });
  },
});

export const membershipCodesReducer = membershipCodesSlice.reducer;
