import { call } from 'api';

export const fetch = (page, successCallback, errorCallback) => call(
  `reports?page=${page}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const show = (id, successCallback, errorCallback) => call(`reports/${id}`, { method: 'GET' }, successCallback, errorCallback);

export const update = (id, admin_notes, successCallback, errorCallback) => call(
  `reports/${id}`,
  { method: 'PUT', body: JSON.stringify({ admin_notes }) },
  successCallback,
  errorCallback
);

export const deleteTarget = (id, successCallback, errorCallback) => call(
  `reports/${id}/delete-target`,
  { method: 'DELETE' },
  successCallback,
  errorCallback
);
