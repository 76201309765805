import { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { save } from 'store/adsense/actions';
import { Button, Loader } from 'lib';

class AdsenseForm extends PureComponent {
  state = {
    name: '',
  };

  isValid() {
    return this.state.name !== '';
  }

  handleSave = (event) => {
    event.preventDefault();
    this.props.save(this.state, this.clearState);
  };

  clearState = () => {
    this.setState({ name: '' });
  };

  renderContent = () => (
    <form onSubmit={() => this.props.save(this.state)}>
      <label>Slot</label>
      <input
        type='text'
        value={this.state.name}
        onChange={(event) => this.setState({ name: event.target.value })}
      />
      <Button
        disabled={!this.isValid()}
        onClick={this.handleSave}
        type='submit'
      >
        Salva
      </Button>
    </form>
  );

  render() {
    const content = this.props.loading ? <Loader /> : this.renderContent();

    return (
      <div className={classNames('adsense-form', this.props.className)}>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.adsense });
const mapDispatchToProps = (dispatch) => ({
  save: (data, callback) => dispatch(save(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdsenseForm);
