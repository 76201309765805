import { call } from 'api';

export const all = (successCallback, errorCallback) => call('custom_banners/all', { method: 'GET' }, successCallback, errorCallback);

export const create = (data, successCallback, errorCallback) => call(
  'custom_banners',
  { method: 'POST', body: JSON.stringify(data) },
  successCallback,
  errorCallback
);

export const fetch = (page, successCallback, errorCallback) => call(
  `custom_banners?page=${page}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const deleteBanner = (id, successCallback, errorCallback) => call(
  `custom_banners/${id}`,
  { method: 'DELETE' },
  successCallback,
  errorCallback
);

export const show = (id, successCallback, errorCallback) => call(
  `custom_banners/${id}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const update = (id, data, successCallback, errorCallback) => call(
  `custom_banners/${id}`,
  { method: 'PUT', body: JSON.stringify(data) },
  successCallback,
  errorCallback
);
