import { Button, Table, Td, Th, Tr } from 'lib';
import { Link } from 'react-router-dom';

export const Adsenses = ({ adsenses, deleteAdsense }) => (
  <Table
    className='adsense-slot-list uk-margin-top'
    head={
      <Tr>
        <Th className='uk-table-shrink'>ID</Th>
        <Th className='uk-table-shrink'>Slot</Th>
        <Th className='uk-table-shrink'>Azioni</Th>
      </Tr>
    }
    rows={adsenses}
    rowRenderer={(adsense) => (
      <Tr key={`adsense-${adsense.id}`}>
        <Td className='uk-table-shrink'>{adsense.id}</Td>
        <Td>{adsense.name}</Td>
        <Td>
          <Link to={`/adsense/${adsense.id}/modifica`}>Modifica</Link>
          <Button
            onClick={() => {
              if (
                window.confirm('Sei sicuro di voler eliminare questo adsense?')
              )
                deleteAdsense(adsense.id);
            }}
          >
            Elimina
          </Button>
        </Td>
      </Tr>
    )}
  />
);
