import { useEffect } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { SolveReportForm } from 'ui/components';
import { fetchReport, updateReport } from 'store/reports/actions';
import { useParams } from 'react-router-dom';

export const EditReportPage = (props) => {
  const { id } = useParams();
  useEffect(() => {
    props.fetch(id);
  }, []);

  return (
    <Page title='Solve Report' className='solve-report'>
      <Header title='Risolvi Segnalazione' />
      <WorkspaceContent>
        <SolveReportForm
          {...props.report}
          onUpdate={props.update}
        />
      </WorkspaceContent>
    </Page>
  );
};

const mapStateToProps = (state) => ({ report: state.reports.report });
const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(fetchReport(id)),
  update: (id, admin_notes) => dispatch(updateReport(id, admin_notes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReportPage);
