import update from 'immutability-helper';

export const showLoader = (state) => update(state, { loading: { $set: true } });
export const hideLoader = (state) => update(state, { loading: { $set: false } });

export const offerCategoriesFetched = (state, action) => update(state, {
    offerCategories: { $set: action.offerCategories },
  });

export const offerCategoriesFetchFailed = (state) => update(state, { error: { $set: true } });
