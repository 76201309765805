import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,
  adsense: null,
  adsenses: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.ADSENSE_CREATED]: reducerActions.adsenseCreated,
  [actionTypes.ADSENSES_FETCHED]: reducerActions.adsensesFetched,
  [actionTypes.ADSENSE_DELETED]: reducerActions.adsenseDeleted,
  [actionTypes.ADSENSE_FETCHED]: reducerActions.adsenseFetched,
  [actionTypes.ADSENSE_ALL_FETCHED]: reducerActions.allAdsensesFetched,
};

export default reducer(mapper, initialState);
