import { Navigate, Outlet } from 'react-router-dom';
import { isLoggedIn } from 'helpers';

export const PrivateRoute = () => {
  const auth = isLoggedIn(); // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to='/accedi' />;
};
export default PrivateRoute;
