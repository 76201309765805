import { call } from 'api';

export const index = (page = 1, successCallback, errorCallback) => call(
  `matches?page=${page}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const update = (match, successCallback, errorCallback) => call(
  `matches/${match.id}`,
  { method: 'PUT', body: JSON.stringify({ result: match.result }) },
  successCallback,
  errorCallback
);

export const deleteMatch = (matchId, successCallback, errorCallback) => call(
  `matches/${matchId}`,
  { method: 'DELETE' },
  successCallback,
  errorCallback
);
