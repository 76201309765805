import { call } from 'api';

export const index = (page = 1, successCallback, errorCallback) => call(
  `club-manager-profiles?page=${page}`,
  { method: 'GET' },
  successCallback,
  errorCallback
);

export const approve = (
  clubManagerProfileId,
  notes,
  successCallback,
  errorCallback
) => call(
  `club-manager-profiles/${clubManagerProfileId}/approve`,
  { method: 'PUT', body: JSON.stringify({ notes }) },
  successCallback,
  errorCallback
);

export const reject = (
  clubManagerProfileId,
  notes,
  successCallback,
  errorCallback
) => call(
  `club-manager-profiles/${clubManagerProfileId}/reject`,
  { method: 'PUT', body: JSON.stringify({ notes }) },
  successCallback,
  errorCallback
);
