import reducer from 'store/reducerGenerator';

import * as actionTypes from './actionTypes';
import * as reducerActions from './reducerActions';

const initialState = {
  loading: false,
  error: false,
  report: null,
  reports: null,
  page: 1,
  totalPages: 0,
};

const mapper = {
  [actionTypes.REPORTS_FETCHED]: reducerActions.reportsFetched,
  [actionTypes.REPORT_FETCHED]: reducerActions.reportFetched,
};

export default reducer(mapper, initialState);
