import { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { save } from 'store/customBanners/actions';
import { Button, Loader } from 'lib';

class CustomBannerForm extends PureComponent {
  state = {
    image: '',
    destination: '',
  };

  isValid() {
    const { image, destination } = this.state;
    return image !== '' && destination !== '';
  }

  handleSave = (event) => {
    event.preventDefault();
    this.props.save(this.state);
  };

  handleFile = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => this.setState({ image: reader.result });
    reader.readAsDataURL(file);
  };

  renderContent = () => (
    <form onSubmit={() => this.props.save(this.state)}>
      <label>Immagine</label>
      <input type='file' onChange={this.handleFile} />
      <label>Destinazione</label>
      <input
        type='text'
        value={this.state.destination}
        onChange={(event) => this.setState({ destination: event.target.value })}
      />
      <Button
        disabled={!this.isValid()}
        onClick={this.handleSave}
        type='submit'
      >
        Salva
      </Button>
    </form>
  );

  render() {
    const content = this.props.loading ? <Loader /> : this.renderContent();

    return (
      <div className={classNames('banner-form', this.props.className)}>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.banner });
const mapDispatchToProps = (dispatch) => ({
  save: (data) => dispatch(save(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomBannerForm);
