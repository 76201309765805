import * as theme from '../theme';

const style = {
  paddingLeft: theme.sizes.gutter.xs,
};

const MainMenuBottomArea = ({ children }) => (
  <div className='uk-position-bottom' style={style}>
    {children}
  </div>
);

export default MainMenuBottomArea;
