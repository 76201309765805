import * as theme from '../theme';

const style = {
  width: '200px',
  height: '100vh',
  backgroundColor: theme.colors.secondaryColor,
  paddingLeft: theme.sizes.gutter.xs,
  paddingTop: theme.sizes.gutter.xs,
  paddingBottom: theme.sizes.gutter.xs,
  boxSizing: 'border-box',
  color: theme.colors.white,
};

const SecondaryMenu = ({ children }) => (
  <div className='secondary-menu uk-inline' style={style} data-uk-sticky>
    {children}
  </div>
);

export default SecondaryMenu;
