import API from 'api';

import { setCurrentUser, unsetCurrentUser } from 'helpers/authentication';
import * as actionTypes from './actionTypes';

const showLoader = () => ({ type: actionTypes.SHOW_AUTHENTICATION_LOADER });
const hideLoader = () => ({ type: actionTypes.HIDE_AUTHENTICATION_LOADER });

export const login = (credentials) => (dispatch) => {
  dispatch(showLoader());

  API.authentication.login(
    credentials,
    (response) => {
      setCurrentUser({ ...response.user, user_id: response.user.id });
      dispatch(loginSucceded(response));
      dispatch(hideLoader());
      window.location.href = '/';
    },
    (error) => {
      dispatch(loginFailed(error));
      dispatch(hideLoader());
    }
  );
};

const loginSucceded = (user) => ({ type: actionTypes.LOGIN_SUCCEDED, user });
const loginFailed = (error) => ({ type: actionTypes.LOGIN_FAILED, error });

export const logout = (token) => (dispatch) => {
  dispatch(showLoader());

  API.authentication.logout(
    token,
    () => {
      unsetCurrentUser();
      dispatch(logoutSucceded());
      dispatch(hideLoader());
      window.location.href = '/accedi';
    },
    (error) => {
      dispatch(logoutFailed(error));
      dispatch(hideLoader());
    }
  );
};

const logoutSucceded = () => ({ type: actionTypes.LOGOUT_SUCCEDED });
const logoutFailed = (error) => ({ type: actionTypes.LOGOUT_FAILED, error });
