import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Page } from 'ui/elements';
import { Header, WorkspaceContent } from 'lib';
import { Reports, PaginationBar } from 'ui/components';
import { fetchReports } from 'store/reports/actions';

class ReportsPage extends PureComponent {
  componentDidMount() {
    const { fetch, page } = this.props;
    fetch(page);
  }

  render() {
    const { page, totalPages, fetch } = this.props;
    return (
      <Page title='Segnalazioni' className='reports'>
        <Header title='Gestione Segnalazioni' />
        <WorkspaceContent>
          <Reports {...this.props} />
          <PaginationBar
            currentPage={page}
            totalPages={totalPages}
            onPreviousPageClick={() => fetch(page - 1)}
            onNextPageClick={() => fetch(page + 1)}
            onPageClick={(page) => fetch(page)}
          />
        </WorkspaceContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.reports });
const mapDispatchToProps = (dispatch) => ({
  fetch: (page = 1) => dispatch(fetchReports(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
