export default {
  borderRadius: '3px',

  gutter: {
    xl: '50px',
    rg: '30px',
    md: '20px',
    sm: '15px',
    xs: '10px',
    xxs: '5px',
  },

  font: {
    xl: '20pt',
    rg: '14pt',
    md: '12pt',
    sm: '10pt',
  },
};
